function initDestinationsMap() {
    var destinationsMap = $('.destinations-map');

    if (destinationsMap.length === 0) {
        // no map found
        return;
    }

    var destinationsMapMap = destinationsMap.find('.destinations-map__map');

    var minZoomLevel = 3;
    var initialLat = 15;
    var initialLng = 0;
    var isMobile = $(window).outerWidth() < 900;
    if ( isMobile === true ) {
        minZoomLevel = 2;
        initialLat = 0;
        initialLng = -15;
    }

    var map = new google.maps.Map(destinationsMapMap[0], {
        center: new google.maps.LatLng(initialLat, initialLng),
        zoom: 3,
        minZoom: minZoomLevel,
        gestureHandling: 'cooperative',
        scrollwheel: false,
        mapTypeControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        zoomControl: true,
        zoomControlOptions: {
            position: google.maps.ControlPosition.LEFT_BOTTOM
        },
        streetViewControl: false,
        fullscreenControl: false,
        draggableCursor: 'initial',
        backgroundColor: '#587b8d',
        styles: [
            {
                featureType: 'all',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        weight: '2.00'
                    },
                    {
                        visibility: 'simplified'
                    }
                ]
            },
            {
                featureType: 'all',
                elementType: 'geometry.stroke',
                stylers: [
                    {
                        color: '#9c9c9c'
                    },
                    {
                        visibility: 'on'
                    }
                ]
            },
            {
                featureType: 'all',
                elementType: 'labels.text',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                featureType: 'administrative.land_parcel',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        visibility: 'on'
                    },
                    {
                        color: '#372305'
                    },
                    {
                        saturation: '-25'
                    }
                ]
            },
            {
                featureType: 'landscape',
                elementType: 'all',
                stylers: [
                    {
                        color: '#eaeee0'
                    },
                    {
                        visibility: 'on'
                    }
                ]
            },
            {
                featureType: 'landscape',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        color: '#ffffff'
                    }
                ]
            },
            {
                featureType: 'landscape.man_made',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        color: '#ffffff'
                    }
                ]
            },
            {
                featureType: 'landscape.natural',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        color: '#eaeee0'
                    }
                ]
            },
            {
                featureType: 'landscape.natural',
                elementType: 'labels',
                stylers: [
                    {
                        color: '#666666'
                    }
                ]
            },
            {
                featureType: 'landscape.natural',
                elementType: 'labels.text',
                stylers: [
                    {
                        color: '#666666'
                    }
                ]
            },
            {
                featureType: 'landscape.natural',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#ffffff'
                    }
                ]
            },
            {
                featureType: 'landscape.natural',
                elementType: 'labels.icon',
                stylers: [
                    {
                        gamma: '5.52'
                    }
                ]
            },
            {
                featureType: 'poi',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                featureType: 'poi',
                elementType: 'labels',
                stylers: [
                    {
                        color: '#838c6b'
                    },
                    {
                        visibility: 'on'
                    }
                ]
            },
            {
                featureType: 'poi',
                elementType: 'labels.text',
                stylers: [
                    {
                        color: '#6d6b6b'
                    },
                    {
                        visibility: 'on'
                    }
                ]
            },
            {
                featureType: 'poi',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#838c6b'
                    }
                ]
            },
            {
                featureType: 'poi',
                elementType: 'labels.text.stroke',
                stylers: [
                    {
                        color: '#fefefe'
                    }
                ]
            },
            {
                featureType: 'poi',
                elementType: 'labels.icon',
                stylers: [
                    {
                        color: '#838c6b'
                    }
                ]
            },
            {
                featureType: 'poi.attraction',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        color: '#666666'
                    },
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                featureType: 'poi.park',
                elementType: 'geometry',
                stylers: [
                    {
                        visibility: 'on'
                    },
                    {
                        color: '#eaeee0'
                    }
                ]
            },
            {
                featureType: 'road',
                elementType: 'all',
                stylers: [
                    {
                        saturation: -100
                    },
                    {
                        lightness: 45
                    }
                ]
            },
            {
                featureType: 'road',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        color: '#eeeeee'
                    }
                ]
            },
            {
                featureType: 'road',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#7b7b7b'
                    }
                ]
            },
            {
                featureType: 'road',
                elementType: 'labels.text.stroke',
                stylers: [
                    {
                        color: '#ffffff'
                    }
                ]
            },
            {
                featureType: 'road.highway',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'simplified'
                    }
                ]
            },
            {
                featureType: 'road.arterial',
                elementType: 'labels.icon',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                featureType: 'transit',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                featureType: 'water',
                elementType: 'all',
                stylers: [
                    {
                        color: '#46bcec'
                    },
                    {
                        visibility: 'on'
                    }
                ]
            },
            {
                featureType: 'water',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        color: '#aac8d1'
                    }
                ]
            },
            {
                featureType: 'water',
                elementType: 'labels',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                featureType: 'water',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#070707'
                    }
                ]
            },
            {
                featureType: 'water',
                elementType: 'labels.text.stroke',
                stylers: [
                    {
                        color: '#ffffff'
                    }
                ]
            }
        ]
    });

    // custom map marker overlay
    function CustomMarker(map, args) {
        if (args) {
            this.latlng = new google.maps.LatLng(args.LatLng[0], args.LatLng[1]);
            this.id = args.Id;
            this.name = args.Name;
            this.location = args.Location;
            this.filterCategories = args.FilterCategories;
        }
        this.visible = true;
        this.setMap(map);
    }

    CustomMarker.prototype = new google.maps.OverlayView();

    CustomMarker.prototype.draw = function() {
        if (!this.latlng) {
            return;
        }

        var div = this.div;

        if (!div) {
            div = this.div = document.createElement('div');

            div.className = 'destinations-map__marker';
            div.setAttribute('data-id', this.id);

            var divIcon = document.createElement('div');
            divIcon.className = 'destinations-map__marker-icon js-open-destination-map-popup';
            divIcon.setAttribute('data-id', this.id);
            divIcon.setAttribute('data-lat-lng', JSON.stringify(this.latlng.toJSON()));

            div.appendChild(divIcon);

            var divName = document.createElement('div');
            divName.className = 'destinations-map__marker-name';
            divName.innerHTML = this.name;

            div.appendChild(divName);

            var divLocation = document.createElement('div');
            divLocation.className = 'destinations-map__marker-location';
            divLocation.innerHTML = this.location;

            div.appendChild(divLocation);

            var panes = this.getPanes();
            panes.overlayImage.appendChild(div);
        }

        var projection = this.getProjection();

        var point = projection.fromLatLngToDivPixel(this.latlng);

        if (point) {
            div.style.left = point.x - 185 + 'px';
            div.style.top = point.y - 47 + 'px';
        }

        if (isMobile || map.boundsChecked) {
            // links aren't shown or bound have already been checked, so no need to offset map points
            return;
        }

        // check for bounds fit
        var boundsRight = projection.fromLatLngToContainerPixel(bounds.getNorthEast());
        var linksLeft = $('.destinations-map__links').offset().left;

        var delta = boundsRight.x - linksLeft;

        if (delta > -125) {
            // set new bounds right with delta + 125 pixels
            var boundsHeight = projection.fromLatLngToContainerPixel(bounds.getSouthWest()).y - boundsRight.y;
            var newRightPoint = new google.maps.Point(boundsRight.x + delta + 125, boundsRight.y + boundsHeight / 2);
            var newRight = projection.fromContainerPixelToLatLng(newRightPoint);

            bounds.extend(newRight);

            map.fitBounds(bounds);
        }

        map.boundsChecked = true;
    };

    CustomMarker.prototype.remove = function() {
        if (this.div) {
            this.div.parentNode.removeChild(this.div);
            this.div = null;
        }
    };

    CustomMarker.prototype.getPosition = function() {
        return this.latlng;
    };

    CustomMarker.prototype.getDraggable = function() {
        return false;
    };

    CustomMarker.prototype.getVisible = function() {
        return this.visible;
    };

    CustomMarker.prototype.setVisible = function(val) {
        if (this.div) {
            this.div.style.display = val ? 'block' : 'none';
        }
        this.visible = val;
    };

    var markers = [];
    var bounds = new google.maps.LatLngBounds();

    // add destination markers
    $.each(destinationsMapMap.data('markers'), function(i, el) {
        if (!el.LatLng || el.LatLng.length < 2) {
            return false;
        }

        var marker = new CustomMarker(map, el);

        bounds.extend(marker.getPosition());

        markers.push(marker);
    });

    // set marker clusterer
    var mapClusterer = new MarkerClusterer(map, markers, {
        gridSize: 17,
        ignoreHidden: true,
        zoomOnClick: false,
        styles: [
            {
                url: '',
                width: 22,
                height: 22,
                anchorText: [0, 0],
                anchorIcon: [11, 11],
                textColor: '#fff'
            }
        ]
    });

    // set map to fit all destination markers on screen
    map.fitBounds(bounds);

    // limit bounds to map markers
    var allowedBounds = false;

    google.maps.event.addListener(map, 'idle', function() {
        if (!allowedBounds) {
            allowedBounds = map.getBounds();
        }
    });

    var lastValidCenter = map.getCenter();

    google.maps.event.addListener(map, 'center_changed', function() {
        var center = map.getCenter();

        if (allowedBounds && allowedBounds.contains(center)) {
            // still within valid bounds, so save the last valid position
            lastValidCenter = center;
            return;
        }

        // not valid anymore => return to last valid position
        map.panTo(lastValidCenter);
    });

    google.maps.event.addDomListener(window, 'resize', function() {
        // resize/refresh map
        var center = map.getCenter();
        google.maps.event.trigger(map, 'resize');
        map.setCenter(center);
    });

    function zoomTo(level, deferred) {
        if (!deferred) {
            deferred = $.Deferred();
        }

        var currentZoom = map.getZoom();
        var desiredZoom = level;

        if (currentZoom === desiredZoom) {
            // all done
            deferred.resolve();
        } else {
            var zoomOut = currentZoom > desiredZoom;

            var nextLevel = currentZoom + (zoomOut ? -1 : 1);

            map.setZoom(nextLevel);

            // SB: Why is this here? It's recursively calling itself and stopping panning and zooming from working after choosing the "All" filter
            /*setTimeout(function() {
                zoomTo(level, deferred);
            }, 25);*/
        }

        if ( desiredZoom >= 4 ) {
            $('.destinations-map__links-reset').css("display", "block");
        } else {
            $('.destinations-map__links-reset').css("display", "none");
        }

        return deferred;
    }

    function panTo(latLng) {
        var deferred = $.Deferred();

        /*console.log('panTo() latLng', latLng);
        console.log('panTo() latLng.lat()', latLng.lat());
        console.log('panTo() latLng.lng()', latLng.lng());
        console.log('panTo() bounds', bounds);*/

        if (latLng) {
            map.panTo(latLng);
        } else {
            map.fitBounds(bounds);
        }

        deferred.resolve();

        return deferred;
    }

    function getZoomByBounds( map, bounds ){
        var MAX_ZOOM = map.mapTypes.get( map.getMapTypeId() ).maxZoom || 21 ;
        var MIN_ZOOM = map.mapTypes.get( map.getMapTypeId() ).minZoom || 0 ;
      
        var ne = map.getProjection().fromLatLngToPoint( bounds.getNorthEast() );
        var sw = map.getProjection().fromLatLngToPoint( bounds.getSouthWest() ); 
      
        var worldCoordWidth = Math.abs(ne.x-sw.x);
        var worldCoordHeight = Math.abs(ne.y-sw.y);
      
        //Fit padding in pixels 
        var FIT_PAD = 40;
      
        for( var zoom = MAX_ZOOM; zoom >= MIN_ZOOM; --zoom ){ 
            if( worldCoordWidth*(1<<zoom)+2*FIT_PAD < $(map.getDiv()).width() && 
                worldCoordHeight*(1<<zoom)+2*FIT_PAD < $(map.getDiv()).height() )
                return zoom;
        }
        return 0;
    }

    var isZooming = false;
    var activeClusterMarkers = null;
    var activeClusterZoom = null;
    var activeClusterCenter = null;

    // zoom to cluster markers
    google.maps.event.addListener(mapClusterer, 'clusterclick', function(c) {
        if (isZooming) {
            // prevent zooming to happen twice
            return;
        }

        isZooming = true;

        // close any active markers or popups
        $('.destinations-map__link').removeClass('destinations-map__link--active');
        $('.destinations-map__marker').removeClass('destinations-map__marker--active');
        $('.destinations-map__popup').removeClass('destinations-map__popup--active');

        var bounds = c.getBounds();
        var latLng = bounds.getCenter();

        // get minimum zoom level that will fit the cluster
        var clusterZoom = Math.max(getZoomByBounds(map, bounds) - 3, 6);

        if (!isMobile) {
            $.when()
                .then(function() {
                    return zoomTo(3);
                })
                .then(function() {
                    return panTo(latLng);
                })
                .then(function() {
                    return zoomTo(clusterZoom);
                })
                .done(function() {
                    isZooming = false;
                });
        } else {
            $.when()
                .then(function() {
                    return zoomTo(3);
                })
                .then(function() {
                    return panTo(latLng);
                })
                .then(function() {
                    return zoomTo(clusterZoom);
                })
                .done(function() {
                    isZooming = false;
                });
        }

        activeClusterMarkers = c.getMarkers();
        activeClusterZoom = clusterZoom;
        activeClusterCenter = latLng;

        // hide hotel links that aren't in the current cluster
        $('.destinations-map__link').hide();

        activeClusterMarkers.forEach(function(m) {
            $('.destinations-map__link[data-id="' + m.id + '"]').show();
        });
    });

    google.maps.event.addListener(mapClusterer, 'clusteringend', function() {
        if (!isZooming && activeClusterMarkers) {
            // when all markers are hidden, the cluster is back in place
            var visibleClusterMarkers = activeClusterMarkers.filter(function(m) {
                return m.map !== null;
            });

            if (visibleClusterMarkers.length === 0) {
                activeClusterMarkers = null;
                activeClusterZoom = null;
                activeClusterCenter = null;

                // show all hotel links
                $('.destinations-map__link').show();
            }
        }
    });

    // highlights map point and link
    destinationsMap
        .on('mouseenter', '.js-open-destination-map-popup', function(e) {
            var id = $(this).data('id');

            var marker = $('.destinations-map__marker-icon[data-id="' + id + '"]');
            marker.parent('.destinations-map__marker').addClass('destinations-map__marker--hover');

            var link = $('.destinations-map__link[data-id="' + id + '"]');
            link.addClass('destinations-map__link--hover');
        })
        .on('mouseleave', '.js-open-destination-map-popup', function(e) {
            var id = $(this).data('id');

            var marker = $('.destinations-map__marker-icon[data-id="' + id + '"]');
            marker.parent('.destinations-map__marker').removeClass('destinations-map__marker--hover');

            var link = $('.destinations-map__link[data-id="' + id + '"]');
            link.removeClass('destinations-map__link--hover');
        });

    // open popup with destination details on click of map marker or side title
    destinationsMap.on('click', '.js-open-destination-map-popup', function(e) {
        if (isZooming) {
            // prevent zooming to happen twice
            //return; // SB: Having to remove this 
        }

        isZooming = true;

        var id = $(this).data('id');

        // close any active markers or popups
        $('.destinations-map__link').removeClass('destinations-map__link--active');
        $('.destinations-map__marker').removeClass('destinations-map__marker--active');
        $('.destinations-map__popup').removeClass('destinations-map__popup--active');

        var marker = $('.destinations-map__marker-icon[data-id="' + id + '"]');

        var latLng = $(this).data('lat-lng');

        if (!isMobile) {
            $.when()
                .then(function() {
                    marker.parent('.destinations-map__marker').addClass('destinations-map__marker--active');
                })
                .then(function() {
                    return zoomTo(3);
                })
                .then(function() {
                    return panTo(latLng);
                })
                .then(function() {
                    return zoomTo(activeClusterZoom || 7);
                })
                .done(function() {
                    isZooming = false;
                });
        } else {
            isZooming = false;
        }

        var link = $('.destinations-map__link[data-id="' + id + '"]');

        link.addClass('destinations-map__link--active');

        var popup = $('.destinations-map__popup[data-id="' + id + '"]');

        popup.addClass('destinations-map__popup--active');
    });

    // SB: moved function to end
    /*$('body').on('click', '.js-close-destination-map-popup', function(e) {
        if (isZooming) {
            // prevent zooming to happen twice
            return;
        }

        isZooming = true;

        var popup = $(this).closest('.destinations-map__popup');

        popup.removeClass('destinations-map__popup--active');

        var id = popup.data('id');

        var marker = $('.destinations-map__marker-icon[data-id="' + id + '"]');

        if (!isMobile) {
            $.when()
                .then(function() {
                    return zoomTo(activeClusterZoom || 3);
                })
                .then(function() {
                    return panTo(activeClusterCenter);
                })
                .then(function() {
                    marker.parent('.destinations-map__marker').removeClass('destinations-map__marker--active');
                })
                .done(function() {
                    isZooming = false;
                });
        } else {
            isZooming = false;
        }

        var link = $('.destinations-map__link[data-id="' + id + '"]');

        link.removeClass('destinations-map__link--active');
    });*/

    destinationsMap.closest('[data-filter]').on('filter-changed', function(e, filterCategory) {
        return; // Don't listen to the filters!!!
        var visibleMarkerBounds = new google.maps.LatLngBounds();

        // only show markers that match the filtered categories
        markers.forEach(function(m) {
            /*console.log('filterCategory', filterCategory);
            console.log('m', m);*/
            var markerMatchesFilterCategory = !(filterCategory && (!m.filterCategories || m.filterCategories.indexOf(filterCategory) === -1));
            
            m.setVisible(markerMatchesFilterCategory);
            
            if (markerMatchesFilterCategory) {
                visibleMarkerBounds.extend(m.getPosition());
            }
        });
    
        // refresh clusters
        mapClusterer.repaint();
        
        // pan and zoom to visible markers
        /*console.log('Visible marker bounds ', visibleMarkerBounds);
        console.log('Visible marker bounds center', visibleMarkerBounds.getCenter());*/
        panTo(visibleMarkerBounds.getCenter());
        
        //zoomTo(filterCategory ? 4 : 2);
        
        if ( typeof filterCategory === 'undefined' ) {
            zoomTo(2);
        } else {
            zoomTo(4);
        }
    });

    // initialise the popup sliders
    $('.destinations-map__popup-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2500,
        animationSpeed: 200,
        arrows: true,
        dots: false,
        infinite: true,
        prevArrow: '<button type="button" class="control-arrow normal control-prev control-prev--light"></button>',
        nextArrow: '<button type="button" class="control-arrow normal control-next control-next--light"></button>'
    });


    //reset map
    $('.destinations-map__links-reset').click(function() {
        map.fitBounds(bounds);
        $(this).css("display", "none");
    })
    
    
    
    
    
    
    $('.js-close-destination-map-popup').on('click', function(e) {
        if (isZooming) {
            // prevent zooming to happen twice
            //return;
        }

        isZooming = true;

        var popup = $(this).closest('.destinations-map__popup');

        popup.removeClass('destinations-map__popup--active');

        var id = popup.data('id');

        var marker = $('.destinations-map__marker-icon[data-id="' + id + '"]');

        if (!isMobile) {
            $.when()
                .then(function() {
                    return zoomTo(activeClusterZoom || 3);
                })
                .then(function() {
                    return panTo(activeClusterCenter);
                })
                .then(function() {
                    marker.parent('.destinations-map__marker').removeClass('destinations-map__marker--active');
                })
                .done(function() {
                    isZooming = false;
                });
        } else {
            isZooming = false;
        }

        var link = $('.destinations-map__link[data-id="' + id + '"]');

        link.removeClass('destinations-map__link--active');
    });
}

$(document).on('google_maps_callback', initDestinationsMap);
