function initHotelLocation() {
    var $hotelLocationMaps = $('.js-hotel-location-map');

    if ($hotelLocationMaps.length === 0) {
        return;
    }

    $hotelLocationMaps.each(function() {
        var $hotelLocationMap = $(this);

        var lat = $hotelLocationMap.data('lat');
        var lng = $hotelLocationMap.data('lng');

        var map = new google.maps.Map($hotelLocationMap.get(0), {
            center: new google.maps.LatLng(lat, lng),
            zoom: 13,
            scrollwheel: false,
            mapTypeControl: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.LEFT_BOTTOM
            },
            clickableIcons: false,
            styles: [
            {
                featureType: 'all',
                elementType: 'all',
                stylers: [
                {
                    lightness: '50'
                },
                {
                    saturation: '-50'
                }
                ]
            },
            {
                featureType: 'administrative',
                elementType: 'labels.text.fill',
                stylers: [
                {
                    color: '#444444'
                },
                {
                    lightness: '50'
                }
                ]
            },
            {
                featureType: 'administrative.province',
                elementType: 'labels.text',
                stylers: [
                {
                    weight: '0.55'
                }
                ]
            },
            {
                featureType: 'administrative.locality',
                elementType: 'labels.text',
                stylers: [
                {
                    weight: '1.23'
                }
                ]
            },
            {
                featureType: 'administrative.locality',
                elementType: 'labels.text.fill',
                stylers: [
                {
                    weight: '0.92'
                }
                ]
            },
            {
                featureType: 'administrative.locality',
                elementType: 'labels.text.stroke',
                stylers: [
                {
                    weight: '0.55'
                }
                ]
            },
            {
                featureType: 'administrative.neighborhood',
                elementType: 'labels.text',
                stylers: [
                {
                    weight: '0.55'
                },
                {
                    visibility: 'simplified'
                },
                {
                    lightness: '50'
                }
                ]
            },
            {
                featureType: 'administrative.neighborhood',
                elementType: 'labels.text.fill',
                stylers: [
                {
                    weight: '0.70'
                },
                {
                    visibility: 'on'
                }
                ]
            },
            {
                featureType: 'administrative.neighborhood',
                elementType: 'labels.text.stroke',
                stylers: [
                {
                    weight: '1.15'
                }
                ]
            },
            {
                featureType: 'landscape',
                elementType: 'geometry',
                stylers: [
                {
                    color: '#ffffff'
                }
                ]
            },
            {
                featureType: 'landscape.natural',
                elementType: 'geometry',
                stylers: [
                {
                    color: '#edfada'
                }
                ]
            },
            {
                featureType: 'poi',
                elementType: 'labels.text.fill',
                stylers: [
                {
                    color: '#4e564e'
                },
                {
                    visibility: 'on'
                },
                {
                    lightness: '50'
                }
                ]
            },
            {
                featureType: 'poi',
                elementType: 'labels.icon',
                stylers: [
                {
                    color: '#2d5968'
                },
                {
                    visibility: 'on'
                }
                ]
            },
            {
                featureType: 'poi.attraction',
                elementType: 'all',
                stylers: [
                {
                    visibility: 'simplified'
                }
                ]
            },
            {
                featureType: 'poi.park',
                elementType: 'geometry',
                stylers: [
                {
                    color: '#cfe3b5'
                }
                ]
            },
            {
                featureType: 'poi.park',
                elementType: 'labels.text',
                stylers: [
                {
                    lightness: '0'
                }
                ]
            },
            {
                featureType: 'road',
                elementType: 'all',
                stylers: [
                {
                    saturation: -100
                },
                {
                    lightness: 45
                }
                ]
            },
            {
                featureType: 'road',
                elementType: 'geometry.fill',
                stylers: [
                {
                    color: '#e4e4e4'
                }
                ]
            },
            {
                featureType: 'road',
                elementType: 'labels',
                stylers: [
                {
                    lightness: '50'
                }
                ]
            },
            {
                featureType: 'road',
                elementType: 'labels.text',
                stylers: [
                {
                    lightness: '50'
                }
                ]
            },
            {
                featureType: 'road.highway',
                elementType: 'all',
                stylers: [
                {
                    visibility: 'simplified'
                }
                ]
            },
            {
                featureType: 'road.highway',
                elementType: 'labels.text',
                stylers: [
                {
                    lightness: '50'
                }
                ]
            },
            {
                featureType: 'road.highway.controlled_access',
                elementType: 'all',
                stylers: [
                {
                    visibility: 'simplified'
                }
                ]
            },
            {
                featureType: 'road.highway.controlled_access',
                elementType: 'labels.text',
                stylers: [
                {
                    color: '#1a3740'
                },
                {
                    lightness: '50'
                }
                ]
            },
            {
                featureType: 'road.arterial',
                elementType: 'all',
                stylers: [
                {
                    visibility: 'simplified'
                }
                ]
            },
            {
                featureType: 'road.arterial',
                elementType: 'labels.text',
                stylers: [
                {
                    weight: '0.70'
                },
                {
                    lightness: '50'
                }
                ]
            },
            {
                featureType: 'road.arterial',
                elementType: 'labels.icon',
                stylers: [
                {
                    visibility: 'off'
                }
                ]
            },
            {
                featureType: 'road.local',
                elementType: 'all',
                stylers: [
                {
                    visibility: 'simplified'
                }
                ]
            },
            {
                featureType: 'road.local',
                elementType: 'labels.text',
                stylers: [
                {
                    color: '#1a3740'
                },
                {
                    weight: '0.45'
                },
                {
                    lightness: '50'
                }
                ]
            },
            {
                featureType: 'transit.line',
                elementType: 'all',
                stylers: [
                {
                    visibility: 'simplified'
                }
                ]
            },
            {
                featureType: 'transit.station.airport',
                elementType: 'geometry',
                stylers: [
                {
                    color: '#fde8f0'
                }
                ]
            },
            {
                featureType: 'transit.station.bus',
                elementType: 'all',
                stylers: [
                {
                    visibility: 'off'
                }
                ]
            },
            {
                featureType: 'transit.station.rail',
                elementType: 'all',
                stylers: [
                {
                    visibility: 'off'
                }
                ]
            },
            {
                featureType: 'transit.station.rail',
                elementType: 'labels.text.fill',
                stylers: [
                {
                    color: '#1a3740'
                }
                ]
            },
            {
                featureType: 'transit.station.rail',
                elementType: 'labels.icon',
                stylers: [
                {
                    visibility: 'off'
                }
                ]
            },
            {
                featureType: 'water',
                elementType: 'all',
                stylers: [
                {
                    color: '#a9cfdb'
                },
                {
                    visibility: 'on'
                }
                ]
            }
            ]
        });

        // add centre postion marker of the hotel
        var centreMarker = new google.maps.Marker({
            position: new google.maps.LatLng(lat, lng),
            icon: {
                url: $hotelLocationMap.data('marker'),
                size: new google.maps.Size(55, 70),
                scaledSize: new google.maps.Size(55, 70)
            },
            cursor: 'drag',
            map: map
        });

        // add other location markers
        var hotelLocationMarkers = [];

        var $hotelLocationMarkers = $hotelLocationMap.siblings('.js-hotel-location-marker');

        var locationMarkers = [];

        $hotelLocationMarkers.each(function() {
            var $hotelLocationMarker = $(this);

            // get SVG image from heading
            var svg = $hotelLocationMarker
            .find('.hotel-location__marker-heading svg')
            .clone()
            .wrap('<div></div>')
            .parent()
            .html();

            var markerColour = $hotelLocationMarker.find('.hotel-location__marker-heading svg circle').css("fill");

            var markerNumber  = $hotelLocationMarker.data('number');

            var hotelLocationMarker = new google.maps.Marker({
                position: new google.maps.LatLng($hotelLocationMarker.data('lat'), $hotelLocationMarker.data('lng')),
                icon: {
                    url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg),
                    size: new google.maps.Size(28, 28),
                    scaledSize: new google.maps.Size(28, 28),
                    anchor: new google.maps.Point(14, 14)
                },
                map: map,
                category: $hotelLocationMarker.data('category'),
                number: $hotelLocationMarker.data('number'),
                colour: $hotelLocationMarker.data('colour'),
                borderColour: $hotelLocationMarker.data('border-colour'),
                textColour: $hotelLocationMarker.data('text-colour')
            });

            locationMarkers.push(hotelLocationMarker);

            hotelLocationMarker.addListener('click', function() {
                // close any other open markers
                $hotelLocationMarkers.not($hotelLocationMarker).removeClass('hotel-location__marker--active');
                $('.hotel-location__map-content').removeClass('marker-active');
                // toggle marker slideout on click
                $hotelLocationMarker.toggleClass('hotel-location__marker--active');

                $hotelLocationMap.trigger('marker_change');
            });

            $hotelLocationMarker.find('.hotel-location__marker-close').on('click', function() {
                // close marker slideout when clicking close button
                $hotelLocationMarker.removeClass('hotel-location__marker--active');

                $hotelLocationMap.trigger('marker_change');
            });

            hotelLocationMarkers.push(hotelLocationMarker);
        });

        map.addListener('click', function() {
            // close any open markers
            $hotelLocationMarkers.removeClass('hotel-location__marker--active');
            
            $hotelLocationMap.trigger('marker_change');
            $('.hotel-location__map-content').removeClass('marker-active');
        });

        centreMarker.addListener('click', function() {
            $hotelLocationMarkers.removeClass('hotel-location__marker--active');
            
            $hotelLocationMap.trigger('marker_change');
            
            $('.hotel-location__map-content').toggleClass('marker-active');
        })

        $hotelLocationMap.on('marker_change', function() {
            // close filters dropdown
            $hotelLocationMap.siblings('.hotel-location__filters').removeClass('hotel-location__filters--expanded');

            // check if a marker is open and adjust minimum height to make slideout fit
            var $activeHotelLocationMarker = $hotelLocationMarkers
            .filter(function() {
                return $(this).hasClass('hotel-location__marker--active');
            })
            .first();

            if ($activeHotelLocationMarker.length > 0) {
                $hotelLocationMap.css('min-height', $activeHotelLocationMarker.find('.hotel-location__marker-content').outerHeight());
            } else {
                $hotelLocationMap.css('min-height', '');
            }
        });

        google.maps.event.addDomListener(window, 'resize', function() {
            var center = map.getCenter();
            google.maps.event.trigger(map, 'resize');
            map.setCenter(center);
        });

        // open/close filters on mobile
        $hotelLocationMap
        .parent()
        .find('.js-hotel-location-filter-toggle')
        .on('click', function() {
            var $hotelLocationFilterToggle = $(this);

            $hotelLocationFilterToggle.parent('.hotel-location__filters').toggleClass('hotel-location__filters--expanded');
        });

        // filter displayed markers based on chosen filter categories

        var $hotelLocationCategories = $hotelLocationMap.parent().find('.js-hotel-location-category');

        $('.hotel-location__filter-all').on('click', function() {
            $(this).toggleClass('off');

            $hotelLocationCategories.each(function() {
                var $category = $(this);
                $category.prop('checked', true);
                $category.trigger('change');
            })
        })

        $hotelLocationCategories.on('change', function() {
            var $hotelLocationCategory = $(this);
            var $otherHotelLocationCategories = $hotelLocationCategories.not($hotelLocationCategory);

            var isThisCategoryChecked = $hotelLocationCategory.is(':checked');
            var isEverythingElseChecked = $otherHotelLocationCategories.not(':checked').length === 0;

            if (!isThisCategoryChecked && isEverythingElseChecked) {
                // first filter click, so uncheck this one and check everything else
                $hotelLocationCategory.prop('checked', false);
                $otherHotelLocationCategories.prop('checked', true);
            }

            // update visible map markers based on selected categories
            var selectedCategories = [];
            var unSelectedCategories = [];

            $hotelLocationCategories.each(function() {
                var $category = $(this);

                if ($category.is(':checked')) {
                    selectedCategories.push($category.val());
                } else {
                    unSelectedCategories.push($category.val());
                }
            });

            hotelLocationMarkers.forEach(function(marker) {
                if ( marker.number < 10 ) {
                    var svgString = '<svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg"><g><circle cx="14" cy="14" r="13" fill="{{marker.colour}}" stroke-width="2" stroke="{{marker.borderColour}}"></circle><text x="8" y="15" dx=".2em" dy=".3em" fill="{{marker.textColour}}" stroke="none" font-family="Sans-serif" font-size="12px">{{marker.number}}</text></g></svg>'

                    if ( unSelectedCategories.indexOf(marker.category) > -1 ) {

                        svgString = svgString.replace('{{marker.colour}}', "#ffffff");
                        svgString = svgString.replace('{{marker.borderColour}}', marker.borderColour);
                        svgString = svgString.replace('{{marker.textColour}}', "#000000");
                        svgString = svgString.replace('{{marker.number}}', marker.number);
                        var decodedIcon = decodeURIComponent(marker.icon.url);
    
                        marker.setIcon({
                            url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svgString),
                            size: new google.maps.Size(28, 28),
                            scaledSize: new google.maps.Size(28, 28),
                            anchor: new google.maps.Point(14, 14)
                        });
                    }
    
                    if ( selectedCategories.indexOf(marker.category) > -1 ) {                
    
                        svgString = svgString.replace('{{marker.colour}}', marker.colour);
                        svgString = svgString.replace('{{marker.borderColour}}', marker.borderColour);
                        svgString = svgString.replace('{{marker.textColour}}', marker.textColour);
                        svgString = svgString.replace('{{marker.number}}', marker.number);
    
                        marker.setIcon({
                            url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svgString),
                            size: new google.maps.Size(28, 28),
                            scaledSize: new google.maps.Size(28, 28),
                            anchor: new google.maps.Point(14, 14)
                        })
                    }
                } else {
                    var svgStringTwoDigits = '<svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg"><g><circle cx="14" cy="14" r="13" fill="{{marker.colour}}" stroke-width="2" stroke="{{marker.borderColour}}"></circle><text x="5" y="15" dx=".2em" dy=".3em" fill="{{marker.textColour}}" stroke="none" font-family="Sans-serif" font-size="12px">{{marker.number}}</text></g></svg>'
                    
                    if ( unSelectedCategories.indexOf(marker.category) > -1 ) {
                        
                        svgStringTwoDigits = svgStringTwoDigits.replace('{{marker.colour}}', "#ffffff");
                        svgStringTwoDigits = svgStringTwoDigits.replace('{{marker.borderColour}}', marker.borderColour)
                        svgStringTwoDigits = svgStringTwoDigits.replace('{{marker.textColour}}', "#000000");
                        svgStringTwoDigits = svgStringTwoDigits.replace('{{marker.number}}', marker.number);
                        var decodedIcon = decodeURIComponent(marker.icon.url);
                        
                        marker.setIcon({
                            url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svgStringTwoDigits),
                            size: new google.maps.Size(28, 28),
                            scaledSize: new google.maps.Size(28, 28),
                            anchor: new google.maps.Point(14, 14)
                        });
                    }
                    
                    if ( selectedCategories.indexOf(marker.category) > -1 ) {                
                        
                        svgStringTwoDigits = svgStringTwoDigits.replace('{{marker.colour}}', marker.colour);
                        svgStringTwoDigits = svgStringTwoDigits.replace('{{marker.borderColour}}', marker.borderColour)
                        svgStringTwoDigits = svgStringTwoDigits.replace('{{marker.textColour}}', marker.textColour);
                        svgStringTwoDigits = svgStringTwoDigits.replace('{{marker.number}}', marker.number);
                        
                        marker.setIcon({
                            url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svgStringTwoDigits),
                            size: new google.maps.Size(28, 28),
                            scaledSize: new google.maps.Size(28, 28),
                            anchor: new google.maps.Point(14, 14)
                        })
                    }
                }
            });

            // close any open markers by triggering click handler
            $hotelLocationMarkers.removeClass('hotel-location__marker--active');
        });

        // initialise any sliders
        $hotelLocationMarkers.find('.hotel-location__marker-slider').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true
        });
    });

    $('.hotel-location__map-content-close').on('click', function() {
        $('.hotel-location__map-content').removeClass('marker-active');
    });
}

$(document).on('google_maps_callback', initHotelLocation);
