
var targetDate;

$(document).foundation();

// These functions are here to run sooner rather than waiting for document.ready:
// openBurgerMenu();
// closeBurgerMenu();
initOffCanvas();

$(document).ready(function () {

    initAccordions();
    initTabs();
    initVerticalCenter();
    openLanguageDropdown();

    initSticky();
    switchGridType();
    initGrid();
    initSliders();
    initAnimateInView();
    animateBorders();
    initPipeSeparators();
    initDateRangePicker();
    initHeroBookingWidget();
    initBespokeSpaBooking();
    initHeaderBookingModal();
    initMiceFormModal();
    initGallery();
    // initVideoHeaders();
    initHotelOffers();
    initTimeline();
    initDateAppend();
    initPropertyListing();
    initOfferFilters();
    // showMenuButtons();
    initMobileBookButton();
    loadVideos();
    initSliderVideo();
    initHero();


    initSubMenuDropdown();
    initRestaurantBooking();
    initSelfbook();

    initCookieWarning();
    initAlignArrows();
    initBookingOverlay();
    initBlog();
    initBlogFilters();
    initCurator();
    initReadMore();
    initTempButtonFix();
    // initCountdowns();


  });

  function initTempButtonFix() {
  $('.filter-controls button').addClass('filter-anything-button');
  }


function initMobileBookButton() {

    $(window).on('scroll', function() {
        var docHeight = $('main').outerHeight();
        var windowHeight = $(window).outerHeight();
        var topOffset = $(document).scrollTop();

        if( $('body').hasClass('hotel') ) {

            if( topOffset > (docHeight - windowHeight ) ) {
                $('.hotel-header__mobile-booking').addClass('over-footer');
            } else {
                $('.hotel-header__mobile-booking').removeClass('over-footer');
            }
        } else {
            if( topOffset > (docHeight - windowHeight ) ) {
                $('.header__mobile-booking').addClass('over-footer');
            } else {
                $('.header__mobile-booking').removeClass('over-footer');
            }
        }
    })

}

function initAlignArrows() {

    function alignArrows() {
      var halfImgHeight = $('.feature-text-image-paged__slide img').first().outerHeight() / 2;

      if ($(window).width() < 630) {
        $('.feature-text-image-paged .slick-arrow').css('top', halfImgHeight + 'px');
      }
    }

$(window).on('resize', alignArrows);
Foundation.onImagesLoaded($('.feature-text-image-paged__slide img'), alignArrows);
}

function initCookieWarning() {
    if (Cookies.get('oetkercollection_accepted') !== undefined) {
        return;
    }

    $('.cookie button').on('click', function() {
        Cookies.set('oetkercollection_accepted', 'cookie warning accepted', { expires: 1 });

        $(this)
            .closest('.cookie')
            .slideUp();
        $('.hero-booking-widget').removeClass('cookie-on');
        $('.scroll').removeClass('cookie-on');
    });

    $('.cookie').slideDown();
    $('.hero-booking-widget').addClass('cookie-on');
    $('.scroll').addClass('cookie-on');
}



function initDateAppend() {
    // get chosen booking filters from cookie
    var bookingFilters = Cookies.get('jumby-bay-booking-filters');

    if (bookingFilters) {
        var bookingFiltersObj = JSON.parse(bookingFilters);

        // append filter values to any Synxis links for Jumby Bay on the current page
        $('a[href*=".synxis.com"]')
            .filter(function () {
                return $(this).is('a[href*="hotel=79712"') || $(this).is('a[href*="Hotel=79712"');
            })
            .each(function () {
                $(this).attr('href', $(this).attr('href') + '&arrive=' + bookingFiltersObj.arrivalDate + '&depart=' + bookingFiltersObj.departureDate + '&adult=' + bookingFiltersObj.guests);
            });
    }
}

function initPropertyListing() {
    if ($('.property-listing').length === 0) {
        return;
    }


    (function() {
        var filterDiv = $('#property-listing-filters')
        var isActive = false;
        $('.property-listing__filters-toggle').on('click', function () {
            isActive = !isActive
            if (isActive === false) {
                filterDiv.css('overflow', 'hidden')
                return
            }
            filterDiv.css('overflow', 'auto')
        })
      })()

    // change view after selecting view option
    $('.property-listing__views input[type="radio"][name="property-listing-view"]').on('change', function () {
        var viewBtn = $(this);

        viewBtn
            .closest('.property-listing')
            .removeClass('property-listing--grid property-listing--list property-listing--map')
            .addClass('property-listing--' + viewBtn.val());

        // trigger resize event
        $('.property-listing__wrapper').trigger('resized');
    });

    // initialise map
    var mapWidth = $(window).outerWidth();
    var mapHeight = Math.min(mapWidth / 1.2, 675);

    var map = L.map('property-listing-map', {
        crs: L.CRS.Simple,
        minZoom: 0,
        maxZoom: 2,
        zoom: 0,
        zoomSnap: 0.5,
        zoomDelta: 0.5,
        center: [0, 0],
        scrollWheelZoom: false,
        maxBoundsViscosity: 1,
        attributionControl: false,
        zoomControl: false,
        gestureHandling: true,
        gestureHandlingOptions: {
            duration: 0,
        },
    });

    L.control
        .zoom({
            position: 'bottomright',
        })
        .addTo(map);

    var mapBounds = [
        [0, 0],
        [mapHeight, mapWidth],
    ];
    var mapCenter = [mapHeight / 2, mapWidth / 2];

    var islandHeight = mapHeight - 40;
    var islandWidth = islandHeight * 1.2; // 6:5 ratio of the island map

    var islandPositionLeft = (mapWidth - islandWidth) / 2;
    var islandPositionTop = (mapHeight - islandHeight) / 2;

    var islandBounds = [
        [islandPositionTop, islandPositionLeft],
        [islandPositionTop + islandHeight, islandPositionLeft + islandWidth],
    ];

    L.imageOverlay('/assets/img/jumby-bay/island.png', islandBounds).addTo(map);

    var markerIcon = L.icon({
        iconUrl: '/assets/img/jumby-bay/map-marker-light.png',
        iconSize: [50, 60],
        iconAnchor: [25, 60],
    });

    // override map marker function to control z-index
    L.Marker.prototype.__setPos = L.Marker.prototype._setPos;
    L.Marker.prototype._setPos = function () {
        L.Marker.prototype.__setPos.apply(this, arguments);
        this._zIndex = 200 + this.options.zIndexOffset;
        this._resetZIndex();
    };

    var mapMarkers = [];

    var zIndexOffSetCounter = 0;

    $('.property-listing__map-property-marker').each(function () {
        var el = $(this);
        var position = el.data('position');

        var marker = L.marker([islandPositionTop + islandHeight * ((100 - position.top) / 100), islandPositionLeft + (islandWidth / 100) * position.left], {
            icon: markerIcon,
            zIndexOffset: zIndexOffSetCounter * 20,
        }).addTo(map);

        marker.propertyId = el.data('property');

        var popup = L.popup({
            maxWidth: 700,
            offset: [0, -24],
        });
        popup.setContent(this);

        marker.bindPopup(popup);

        marker
            .on('popupopen', function (e) {
                // hide the filters bar on mobile
                $('.property-listing__filters').addClass('property-listing__filters--hidden');

                map._container.classList.add('property-listing__map-island--popup-open');

                // on mobile increase the map height to fit the whole popup
                var popupHeight = e.popup._container.offsetHeight;
                var mapHeight = map._container.offsetHeight;

                if (popupHeight > mapHeight) {
                    map._container.style.minHeight = popupHeight + 40 + 'px';
                }

                // make sure that map bounds are big enough to fit popup as well
                var popupPixels = map.project(e.popup.getLatLng());

                var popupSouthWest = map.unproject([popupPixels.x - e.popup._container.offsetLeft, popupPixels.y]);
                var popupNorthEast = map.unproject([popupPixels.x + e.popup._container.offsetLeft, popupPixels.y + e.popup._container.offsetTop - 40]);

                var popupBounds = new L.LatLngBounds(popupSouthWest, popupNorthEast);

                var extendedBounds = new L.LatLngBounds(mapBounds).extend(popupBounds);

                map.setMaxBounds(extendedBounds);

                e.popup._adjustPan();
            })
            .on('popupclose', function (e) {
                // show the filters bar on mobile
                $('.property-listing__filters').removeClass('property-listing__filters--hidden');

                map._container.classList.remove('property-listing__map-island--popup-open');

                // reset back to normal map bounds
                map._container.style.minHeight = null;
                map.setMaxBounds(mapBounds);

                map.invalidateSize();
            });

        mapMarkers.push(marker);

        zIndexOffSetCounter++;
    });

    map.setMaxBounds(mapBounds);
    map.setView(mapCenter, map.getMinZoom());

    $('.property-listing__filters').on('opened.zf.offcanvas closed.zf.offcanvas', function () {
        // trigger resize event
        $('.property-listing__wrapper').trigger('resized');
    });

    $('.property-listing__wrapper').on('resized', function () {
        // make sure the off canvas wrapper is at least the height of the filter pane
        var wrapper = $(this);
        var offCanvas = wrapper.find('.property-listing__filters');

        wrapper.css('min-height', 0);
        wrapper.css('min-height', offCanvas.height() + 30);

        // resize the map
        map.invalidateSize();
    });

    // set up date pickers
    var today = new Date().valueOf();

    $('.property-listing__filters-datepicker')
        .fdatepicker({
            format: 'dd/mm/yyyy',
            leftArrow: '<',
            rightArrow: '>',
            language: 'en',
            onRender: function (date) {
                setTimeout(function () {
                    $('.datepicker-dropdown').css('z-index', '9999999999');
                }, 200);

                var dateValue = date.valueOf();

                if (dateValue <= today) {
                    return 'disabled';
                }

                if (this.element.attr('name') === 'departure') {
                    // highlight arrival date and selected days in between
                    var arrivalDatePicker = $('.property-listing__filters-datepicker[name="arrival"]').data('datepicker');

                    if (arrivalDatePicker) {
                        var arrivalDate = arrivalDatePicker.date.valueOf();
                        var departureDate = this.date.valueOf();

                        if (dateValue < arrivalDate) {
                            return 'disabled';
                        } else if (dateValue === arrivalDate) {
                            return 'disabled selected';
                        } else if (dateValue > arrivalDate && dateValue < departureDate) {
                            return 'selected';
                        }
                    }
                } else if (this.element.attr('name') === 'arrival') {
                    // highlight departure date and selected days in between
                    var departureDatePicker = $('.property-listing__filters-datepicker[name="departure"]').data('datepicker');

                    if (departureDatePicker) {
                        var departureDate = departureDatePicker.date.valueOf();
                        var arrivalDate = this.date.valueOf();

                        if (dateValue > arrivalDate && dateValue <= departureDate) {
                            return 'selected';
                        }
                    }
                }

                return '';
            },
        })
        .on('changeDate', function (e) {
            var picker = $(this).data('datepicker');

            if (picker.element.attr('name') === 'arrival') {
                // ensure departure date is after the arrival date
                var dateValue = e.date.valueOf();

                var departureDatePicker = $('.property-listing__filters-datepicker[name="departure"]').data('datepicker');
                var departureDate = departureDatePicker.date.valueOf();

                if (dateValue > departureDate) {
                    var newDate = new Date(e.date);
                    newDate.setDate(newDate.getDate() + 1);
                    departureDatePicker.update(newDate);
                }

                picker.hide();
                departureDatePicker.show();
            }
        })
        .one('show', function () {
            var picker = $(this).data('datepicker');

            // add extra class for styling
            picker.picker.addClass('datepicker--filters');
        });

    $('.property-listing__filters select,input[type="checkbox"],input.property-listing__filters-datepicker').on('change', function () {
        // get selected filters
        var sortOrder = $('.property-listing__filters-dropdown[name="order-by"]').val() || 'asc';
        var filterRooms = parseInt($('.property-listing__filters-dropdown[name="rooms"]').val() || '0');
        var filterGuests = parseInt($('.property-listing__filters-dropdown[name="guests"]').val() || '0');
        var filterPrice = parseInt($('.property-listing__filters-dropdown[name="price"]').val() || '0');
        var filterFacilities = $('.property-listing__filters-checkbox input[name="facilities"]:checked')
            .map(function () {
                return this.value;
            })
            .get();
        var filterName = $('.property-listing__filters-dropdown[name="villa-name"]').val();

        var filterArrivalDate = null;
        var filterArrival = $('.property-listing__filters-datepicker[name="arrival"]').val();

        if (filterArrival) {
            var filterArrivalArray = filterArrival.split('/');

            filterArrivalDate = new Date(parseInt(filterArrivalArray[2]), parseInt(filterArrivalArray[1]) - 1, parseInt(filterArrivalArray[0]));
        }

        var filterDepartureDate = null;
        var filterDeparture = $('.property-listing__filters-datepicker[name="departure"]').val();

        if (filterDeparture) {
            var filterDepartureArray = filterDeparture.split('/');

            filterDepartureDate = new Date(parseInt(filterDepartureArray[2]), parseInt(filterDepartureArray[1]) - 1, parseInt(filterDepartureArray[0]));
        }

        // apply filters to all listed villas
        var villas = $('.property-listing__item');

        villas.each(function (i, el) {
            var villa = $(el);

            var valid = true;

            var propertyId = villa.data('property-id');
            var rooms = villa.data('rooms');
            var guests = villa.data('guests');
            var price = villa.data('price');
            var facilities = villa.data('facilities');
            var name = villa.data('villa-name');
            var disabledDates = villa.data('disabled-dates');

            if (filterRooms && rooms !== filterRooms) {
                valid = false;
            }

            if (filterGuests && guests < filterGuests) {
                valid = false;
            }

            if (filterPrice && price > filterPrice) {
                valid = false;
            }

            if (filterFacilities && filterFacilities.length > 0 && facilities) {
                var validType = true;

                filterFacilities.forEach(function (ft) {
                    if (facilities.indexOf(ft) < 0) {
                        validType = false;
                    }
                });

                if (!validType) {
                    // not all of the facilities of this villa match the filter
                    valid = false;
                }
            }

            if (filterName && name !== filterName) {
                valid = false;
            }

            if (filterArrivalDate && filterDepartureDate && disabledDates && disabledDates.length > 0) {
                // check that villa doesn't have disabled periods during the selected arrival and departure dates
                disabledDates.forEach(function (dd) {
                    var startDate = new Date(dd.start + 'T00:00');
                    var endDate = new Date(dd.end + 'T00:00');

                    if (
                        (filterArrivalDate >= startDate && filterArrivalDate < endDate) ||
                        (filterDepartureDate > startDate && filterDepartureDate <= endDate) ||
                        (filterArrivalDate < startDate && filterDepartureDate > endDate)
                    ) {
                        valid = false;
                    }
                });
            }

            // toggle visibility class based on filter checks
            villa.toggleClass('property-listing__item--hidden', !valid);

            mapMarkers.forEach(function (marker) {
                // close any open popup
                marker.closePopup();

                if (marker.propertyId !== propertyId) {
                    return;
                }

                // hide or show marker
                marker.setOpacity(valid ? 1 : 0);
            });
        });

        // order the list of valid items
        var visibleVillas = villas.filter(':not(.property-listing__item--hidden)').get();

        var sortedVillas = visibleVillas.sort(function (a, b) {
            var villaAName = $(a).data('villa-name');
            var villaBName = $(b).data('villa-name');

            switch (sortOrder) {
                case 'asc':
                    return villaAName.toUpperCase().localeCompare(villaBName.toUpperCase());
                case 'desc':
                    return villaBName.toUpperCase().localeCompare(villaAName.toUpperCase());
            }
        });

        // set flexbox order for sorted list
        sortedVillas.forEach(function (villa, i) {
            $(villa).css('order', i + 1);
        });

        // show message when no matches have been found
        $('.property-listing__no-results').toggleClass('property-listing__no-results--active', visibleVillas.length === 0);

        // store selected filters in cookie for Synxis booking links
        Cookies.set('jumby-bay-booking-filters', {
            guests: Math.max(filterGuests, 2),
            arrivalDate: filterArrivalDate.getFullYear() + '-' + ('0' + (filterArrivalDate.getMonth() + 1)).slice(-2) + '-' + ('0' + filterArrivalDate.getDate()).slice(-2),
            departureDate: filterDepartureDate.getFullYear() + '-' + ('0' + (filterDepartureDate.getMonth() + 1)).slice(-2) + '-' + ('0' + filterDepartureDate.getDate()).slice(-2),
        });
    });

    /* if ($(window).outerWidth() >= 1425 && $('.property-listing__filters').length > 0) {
        // open the filters panel on larger desktop screens
        $('.property-listing__filters').foundation('open');
    } */

	//$('.property-listing__filters').foundation('open');

    // toggle filters fixed state based on position of the wrapper
    $('.property-listing__wrapper')
        .on('scrollme.zf.trigger', function () {
            var wrapper = $(this);
            var wrapperTop = parseInt(wrapper.offset().top);
            var wrapperBottom = wrapperTop + parseInt(wrapper.outerHeight());
            var filters = wrapper.find('.property-listing__filters');
            var filtersAtBottom = filters.hasClass('property-listing__filters--fixed-bottom');
            var filtersFixedTop = 95;
            var filtersTop = parseInt(filters.offset().top);
            var filtersBottom = filtersTop + parseInt(filters.outerHeight());
            var scrollTop = parseInt($(window).scrollTop());

            var wrapperHasPassedTop = scrollTop - (wrapperTop - filtersFixedTop) >= 0;
            var wrapperPassedBottom = wrapperBottom - filtersBottom < 2 && (!filtersAtBottom || filtersTop - scrollTop < filtersFixedTop);
            var wrapperBottomIsBelowScreen = ((scrollTop + $(window).outerHeight()) < wrapperBottom);

            filters.toggleClass('property-listing__filters--fixed', wrapperHasPassedTop);
            filters.toggleClass('property-listing__filters--fixed-bottom', !wrapperBottomIsBelowScreen && wrapperPassedBottom);

            if (Foundation.MediaQuery.atLeast('medium')) {
                // hide any open date pickers
                $('.property-listing__filters-datepicker').fdatepicker('hide');
            }
        })
        .trigger('scrollme.zf.trigger');

    // if filter content doesn't fit on screen, open it in fixed mode with scroll bar
    var filters = $('.property-listing__filters');

    if (filters.outerHeight() > $(window).outerHeight() - $('.header').outerHeight() - $('.header__book-mobile').outerHeight()) {
        filters.addClass('property-listing__filters--open-fixed');
    }

    if (filters.outerHeight() > $('.property-listing__wrapper .off-canvas-content').outerHeight()) {
        filters.addClass('property-listing__filters--fixed-bottom-absolute');
    }
}

function initHotelOffers() {
    if ($('.offers-filter-accordion').children().length < 3) {
        $('.offers-type').removeClass('small-6');
        $('.offers-type').addClass('small-12');
        $('.offers-type h4').css('text-align', 'center');
    }
}

function initGallery() {
    $('.gallery-modal')
        .on('open.zf.reveal', function (e) {
            $(this)
                .find('img[data-src]')
                .each(function (i, el) {
                    $(el).attr('src', $(el).data('src')).removeAttr('data-src');
                });

            $(this)
                .find('iframe[data-src]')
                .each(function (i, el) {
                    $(el).attr('src', $(el).data('src'));
                });

            var slider = $(this).find('.slider');

            slider.find('.slide').sort(function (a, b) {
                return $(a).data('slide-index') > $(b).data('slide-index');
            }).appendTo(slider);

            slider
                .not('.slick-initialized')
                .slick({
                    slide: ':not(.hide)',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    infinite: false,
                    prevArrow: '<button type="button" class="control-arrow normal control-prev control-prev--light"></button>',
                    nextArrow: '<button type="button" class="control-arrow normal control-next control-next--light"></button>',
                })
                .on('afterChange', function (e) {
                    var slick = $(this).slick('getSlick');

                    var autoplayVideos = $(window).outerWidth() >= 900;

                    slick.$slides.each(function (i, slide) {
                        if (i === slick.currentSlide) {
                            // start video on current slide
                            if (autoplayVideos) {
                                var video = $(slide).find('video.mejs__player');

                                if (video.length > 0) {
                                    var mediaElementPlayer = getMediaElementPlayer(video);
                                    mediaElementPlayer.load();
                                }
                            }
                        } else {
                            // pause video on hidden slide
                            var video = $(slide).find('video.mejs__player');

                            if (video.length > 0) {
                                var mediaElementPlayer = getMediaElementPlayer(video);
                                mediaElementPlayer.pause();
                            }
                        }
                    });
                });

            var videos = slider.find('video.mejs__player');

            if (videos.length > 0) {
                var mediaElementPlayer = getMediaElementPlayer(video);
                mediaElementPlayer.load();
            }

            slider.slick('slickGoTo', $(this).data('start-slide-index') || 0, true);

            slider.slick('refresh');

            $(this).trigger('resizeresizeme.zf.trigger');
        })
        .on('closed.zf.reveal', function (e) {
            $(this).find('.slider.slick-initialized').slick('unslick');

            $(this).find('iframe[data-src]').attr('src', '');
        });

    $('[data-open][data-slide-index]').on('click', function (e) {
        var modal = $('#' + $(this).data('open'));
        modal.data('start-slide-index', $(this).data('slide-index'));
    });
}

function initMiceFormModal() {
    var $miceModal = $('.mice-modal');

    // MICE form opening trigger
    $('a[href="#mice-form"]').on('click', function (e) {
        e.preventDefault();

        $('.mice-form .section-form--success').addClass('hide');
        $('.mice-form .section-form--form').removeClass('hide');

        $miceModal.foundation('open');
    });

    // MICE form date input fields
    var calendarLang = 'en';
    var calendarFormat = 'dd/mm/yyyy';
    switch ($('html').attr('lang')) {
        case 'de':
            calendarLang = 'de';
            break;
        case 'es':
            calendarLang = 'es';
            break;
        case 'pt':
            calendarLang = 'pt';
            break;
        case 'fr':
            calendarLang = 'fr';
            break;
        case 'ru':
            calendarLang = 'ru';
            break;
        case 'zh-CN':
            calendarLang = 'zh-CN';
            calendarFormat = 'yyyy-mm-dd';
            break;
    }

    $('.form-datepicker').fdatepicker({
        format: calendarFormat,
        leftArrow: '<',
        rightArrow: '>',
        language: calendarLang,
        onRender: function (date) {
            setTimeout(function () {
                $('.datepicker-dropdown').css('z-index', '9999999999');
            }, 200);
            return date.valueOf() <= new Date().valueOf() ? 'disabled' : '';
        },
    });

    // MICE custom file upload fields
    $('.field-file input[type="file"]').on('change', function () {
        if (this.files.length > 0) {
            // Check 10MB upload limit
            if (this.files[0].size > 10000000) {
                alert('Maximum file size of 10MB exceeded');
                $(this).prev('.add-file').find('span').text($(this).prev('.add-file').data('placeholder'));
                $('.remove-file').removeClass('visible');
                return;
            }

            $(this).prev('.add-file').find('span').text(this.files[0].name);
            $('.remove-file').addClass('visible');
        } else {
            $(this).prev('.add-file').find('span').text($(this).prev('.add-file').data('placeholder'));
            $('.remove-file').removeClass('visible');
        }
    });

    $('.remove-file').on('click', function () {
        $('.field-file input[type="file"]').val(null);
        $('.add-file').find('span').text($('.add-file').data('placeholder'));
        $(this).removeClass('visible');
    });

    // Hide all options until hotel is selected
    $('.mice-form form select > option[data-hotel-id]').wrap('span');

    // Change visible options based on selected hotel
    $('.mice-form form input[name="location"]').on('change', function (e) {
        var hotelId = $(this).val();

        // clear selected values
        $('.mice-form form select > option[data-hotel-id]').wrap('span').parent('select').val('');
        // only show values for selected hotel
        $('.mice-form form select > span > option[data-hotel-id="' + hotelId + '"]').unwrap();

        // enable or disable seating styles and breakout rooms
        var hideSeatingStyles = $(this).data('hide-seating-style');
        var hideBreakoutRooms = $(this).data('hide-breakout-rooms');

        $('.mice-form form select[name="seating"]').val('').attr('disabled', hideSeatingStyles);
        $('.mice-form form input[name="breakoutrooms"]').val('').attr('disabled', hideBreakoutRooms);
    });

    // Check the required fields have values to submit
    $('.mice-form form input, .mice-form form select').on('change', function () {
        // Change section headings when a section has been completed
        var section1Valid = $('input[name="location"]:checked').val() != 'undefined';

        var section1Title = $($('.mice-form .accordion .accordion-item').get(0));

        section1Title.toggleClass('is-complete', section1Valid);

        var validator = $('.mice-form form').validate({ ignore: [] });
        var formValid = validator.checkForm();
        var invalidElements = validator.invalidElements();

        var section2Valid = !invalidElements.is(
            '[name="title"], [name="firstname"], [name="lastname"], [name="email"], [name="telephone"], ' +
                '[name="company"], [name="address1"], [name="address2"], [name="city"], [name="stateprovince"], ' +
                '[name="zip"], [name="country"]'
        );

        var section2Title = $($('.mice-form .accordion .accordion-item').get(1));

        section2Title.toggleClass('is-complete', section2Valid && !section2Title.hasClass('not-seen'));

        var section3Valid = !invalidElements.is(
            '[name="eventtype"], [name="eventname"], [name="attendees"], [name="preferredarrival"], ' +
                '[name="nights"], [name="alternatearrival"], [name="rooms"], [name="breakoutrooms"], [name="seating"], [name="document"]'
        );

        var section3Title = $($('.mice-form .accordion .accordion-item').get(2));

        section3Title.toggleClass('is-complete', section3Valid && !section3Title.hasClass('not-seen'));

        // Allow form submission on all required fields
        if (formValid && section1Valid) {
            $('.mice-form form button[type="submit"]').removeAttr('disabled').prop('disabled', false)
                .closest('.book-now-container').removeClass('book-now-container--disabled'); // belt 'n braces
        } else {
            $('.mice-form form button[type="submit"]').prop('disabled', true)
                .closest('.book-now-container').addClass('book-now-container--disabled');
        }

        validator.destroy();
    });

    // set types for any pre-selected location
    $('.mice-form form input[name="location"]:checked').trigger('change');

    $('.mice-form form').validate({ ignore: [] });

    // Remove class when user has seen the accordion item
    $('.mice-form form .accordion').on('down.zf.accordion', function (e) {
        $(this).find('.accordion-item.is-active').removeClass('not-seen');
    });

    $('.mice-form form').on('submit', function (e) {
        var form = $(this);

        var formData = new FormData(this);

        $.ajax({
            url: form.attr('action'),
            type: 'POST',
            data: formData,
            processData: false,
            contentType: false,
            beforeSend: function () {
                $('.mice-form .spinner').show();
            },
            success: function () {
                // reset form
                $('.mice-form form')[0].reset();
                $('.mice-form form .accordion .accordion-item').removeClass('is-complete');
                $('.mice-form form .accordion .accordion-item:not(:first-child)').addClass('not-seen');
                $('.mice-form form .accordion').foundation('up', $('.mice-form form .accordion .accordion-item .accordion-content'), true);
                $('.mice-form form .accordion').foundation('down', $('.mice-form form .accordion .accordion-item:first-child .accordion-content'), true);
                $('.mice-form .section-form--form').addClass('hide');
                $('.mice-form .section-form--success').removeClass('hide');
            },
            complete: function () {
                $('.mice-form .spinner').hide();
            },
        });

        e.preventDefault();
        return false;
    });
}

function initBespokeSpaBooking() {
    $('a[href="#bespoke-booking"]').on('click', function(e) {
        // change URL to go directly to the booking page
        var btnHeaderBooking = $('a[data-open="headerSpaBookingModal"]');

        var url = btnHeaderBooking.attr('href');

        url += '&bespoke=true';

        btnHeaderBooking.attr('href', url);

        btnHeaderBooking.trigger('click');

        e.preventDefault();
    });
}

function initHeaderBookingModal() {
    var headerBookingModal = $('.headerBookingModal');
    var blurDisabled = headerBookingModal.hasClass('no-blur');

    if (headerBookingModal.length === 0) {
        return;
    }

    headerBookingModal
        .on('open.zf.reveal', function() {
            if (!blurDisabled) {
                $('#root').addClass('blurred');
            }
        })
        .on('closed.zf.reveal', function() {
            if (!blurDisabled) {
                $('#root').removeClass('blurred');
            }

            if (!headerBookingModal.hasClass('js-no-clear')) {
                headerBookingModal.find('#headerBookingModalContainer').html('<img src="/assets/img/spinner-black.gif" alt="Loading..." class="spinner">');
            }
        });

    headerBookingModal.on('click', '.js-show-treatments', function(e) {
        headerBookingModal.find('.section-landing').addClass('hide');
        headerBookingModal.find('.section-treatments').removeClass('hide');
    });

    headerBookingModal.on('click', '.js-show-daily-rates', function(e) {
        headerBookingModal.find('.section-landing').addClass('hide');
        headerBookingModal.find('.section-daily-rates').removeClass('hide');
    });

    headerBookingModal.on('click', '.js-show-packages', function(e) {
        headerBookingModal.find('.section-landing').addClass('hide');
        headerBookingModal.find('.section-packages').removeClass('hide');
    });

    headerBookingModal.on('click', '.js-back-landing', function(e) {
        headerBookingModal.find('.section-packages').addClass('hide');
        headerBookingModal.find('.section-daily-rates').addClass('hide');
        headerBookingModal.find('.section-treatments').addClass('hide');
        headerBookingModal.find('.section-landing').removeClass('hide');
    });

    headerBookingModal.on('click', '.js-back-treatments', function(e) {
        headerBookingModal.find('.section-packages').addClass('hide');
        headerBookingModal.find('.section-daily-rates').addClass('hide');
        headerBookingModal.find('.section-landing').addClass('hide');
        headerBookingModal.find('.section-form').addClass('hide');
        headerBookingModal.find('.section-treatments').removeClass('hide');

        // check original checkboxes and then empty list of selected treatments
        $('.js-selected-treatments')
            .find('input[type="checkbox"][name="Treatments"]:checked')
            .each(function(i, el) {
                headerBookingModal.find('.section-treatments input[type="checkbox"][name="Treatments"][value="' + $(el).val() + '"]').prop('checked', true);
            })
            .end()
            .empty();
    });

    headerBookingModal.on('click', '.js-show-form', function(e) {
        var form = headerBookingModal.find('form');

        if (form.valid()) {
            // show selected treatments on next page
            var selectedTreatments = form.find('input[type="checkbox"][name="Treatments"]:checked').closest('.checkbox-wrapper');

            selectedTreatments.each(function(i, el) {
                // uncheck selected element
                $(el)
                    .find('input[type="checkbox"][name="Treatments"]')
                    .prop('checked', false);

                // append copy to selected list
                var clone = $(el).clone();
                clone.find('input[type="checkbox"][name="Treatments"]').prop('checked', true);

                $('.js-selected-treatments').append(clone);
            });

            headerBookingModal.find('.section-treatments').addClass('hide');
            headerBookingModal.find('.section-form').removeClass('hide');
        }
    });
}

function initSelfbook() {

    function getURLParameter(url, name) {
        return (RegExp(name + '=' + '(.+?)(&|$)').exec(url)||[,null])[1];
    }

    $('a[href*="#eden-book"]').on('click', function(event) {
        event.preventDefault();
        var roomId = '';
        var promoCode = '';
        var locale = $('html')[0].lang;
        var startDate = '';
        var endDate = '';
        var adult = '2';
        var child = '0';
        var infants = '0';
        var linkUrl = ( $(this).attr('href') );

        roomId = getURLParameter(linkUrl, 'room');
        promoCode = getURLParameter(linkUrl, 'promo-code');
        startDate = getURLParameter(linkUrl, 'arrive');
        endDate = getURLParameter(linkUrl, 'depart');
        adult = getURLParameter(linkUrl, 'adult');
        child = getURLParameter(linkUrl, 'child');
        infants = getURLParameter(linkUrl, 'infants');

        bookNow({
            'startDate': startDate,                      //start_date yyyy/mm/dd
            'endDate': endDate,                         //end_date yyyy/mm/dd
            'guests': [                           //guests (arr)
                {
                    'type': 'adult',
                    'count': adult
                },
                {
                    'type': 'child',
                    'count': child
                },
                {
                    'type': 'infants',
                    'count': infants
                }
            ],
            'propertyId': '',                         //property_id (blank)
            'roomId': roomId,                     //room_id (blank)
            'ratePlanCode': '',                         //rate_plan_code (blank)
            'promoCode': promoCode,                  //promo_code (blank)
            'groupCode': '',                         //group_code (blank)
            'locale': locale,                     //locale (language)
            'isSingleHotel': true                        //isSingleHotel (bool)
        });
    })

}

function onHeaderBookingModalShown() {
    // initialise any Foundation and validation elements
    var headerBookingModal = $('#headerSpaBookingModal');
    var form = headerBookingModal.find('form');

    headerBookingModal.foundation();

    $.validator.unobtrusive.parse(form);
    form.validate();

    // set spa package links
    $('.spa-package .btn[data-label-open]').on('click', function(e) {
        var $el = $(this);

        $el.toggleClass('active');

        $el.text($el.data($el.hasClass('active') ? 'label-close' : 'label-open'));

        $el.closest('.spa-package')
            .find('.spa-package__info')
            .toggleClass('spa-package__info--active');
    });
}

function onHeaderBookingModalFormSubmitted(msg) {
    var headerBookingModal = $('#headerSpaBookingModal');
    var form = headerBookingModal.find('form');

    // show message
    form.find('.js-submitted-message').html(msg);

    headerBookingModal.find('.section-form').addClass('hide');
    headerBookingModal.find('.section-completion').removeClass('hide');
}

function initDateRangePicker() {
    var dateRangePickers = $('.js-date-range-picker');

    if (dateRangePickers.length === 0) {
        // none found
        return;
    }

    var today = new Date();
    var yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    dateRangePickers.each(function (i, el) {
        var $el = $(el);

        var arrivalCalendar = new Datepickk({
            container: $el.siblings('.js-date-range-picker-calendar').find('.js-date-range-picker-arrival')[0],
            inline: true,
            minDate: yesterday,
            maxSelections: 1,
            disabledDays: null,
            disabledDates: null,
            onSelect: function (checked) {
                if (checked) {
                    var displayDate = '';
                    var arrival = arrivalCalendar.selectedDates[0];

                    displayDate += arrival.toLocaleDateString('en-GB');

                    // check if departure date needs moving
                    if (departureCalendar.selectedDates.length > 0) {
                        var departure = departureCalendar.selectedDates[0];

                        if (arrival > departure) {
                            departureCalendar.unselectAll();
                            departureCalendar.selectDate(arrival);
                        }

                        displayDate += ' - ' + departureCalendar.selectedDates[0].toLocaleDateString('en-GB');
                    }

                    // update minimum arrival date
                    departureCalendar.minDate = arrival;

                    // update date form field
                    $el.val(displayDate);
                }
            },
        });

        var departureCalendar = new Datepickk({
            container: $el.siblings('.js-date-range-picker-calendar').find('.js-date-range-picker-departure')[0],
            inline: true,
            minDate: yesterday,
            maxSelections: 1,
            disabledDays: null,
            disabledDates: null,
            onSelect: function (checked) {
                if (checked) {
                    var displayDate = '';
                    var departure = departureCalendar.selectedDates[0];

                    displayDate += departure.toLocaleDateString('en-GB');

                    // check if arrival date needs moving
                    if (arrivalCalendar.selectedDates.length > 0) {
                        var arrival = arrivalCalendar.selectedDates[0];

                        if (departure < arrival) {
                            arrivalCalendar.unselectAll();
                            arrivalCalendar.selectDate(departure);
                        }

                        displayDate = arrivalCalendar.selectedDates[0].toLocaleDateString('en-GB') + ' - ' + displayDate;
                    }

                    // update date form field
                    $el.val(displayDate);
                }
            },
        });

        $el.on('click', function () {
            $el.siblings('.js-date-range-picker-calendar').toggleClass('visible');
        });
    });
}

function initHeroBookingWidget() {
    var $heroBookingWidget = $('.hero-booking-widget');

    if ($heroBookingWidget.length === 0) {
        // nothing to initialise
        return;
    }

    var $heroBookingWidgetCalendars = $heroBookingWidget.find('.hero-booking-widget__calendars');
    var $heroBookingWidgetCalendarsClose = $heroBookingWidget.find('.hero-booking-widget__calendars-close');
    var $heroBookingWidgetPickers = $heroBookingWidget.find('.hero-booking-widget__pickers');
    var $heroBookingWidgetOverlay = $heroBookingWidget.find('.hero-booking-widget__overlay');
    var $heroBookingWidgetDates = $heroBookingWidget.find('.hero-booking-widget__dates');
    var $heroBookingWidgetNumbers = $heroBookingWidget.find('.hero-booking-widget__numbers');
    var $heroBookingWidgetMinus = $heroBookingWidget.find('.hero-booking-widget__minus');
    var $heroBookingWidgetPlus = $heroBookingWidget.find('.hero-booking-widget__plus');
    var $heroBookingWidgetInputs = $heroBookingWidget.find('input[type="number"], input[type="hidden"]');

    // toggle pickers and calendars on button click
    $heroBookingWidgetDates.on('click', function() {
        $heroBookingWidgetCalendars.toggleClass('active');
        $heroBookingWidgetPickers.removeClass('active');

        $heroBookingWidgetOverlay.toggleClass('active', $heroBookingWidgetCalendars.hasClass('active'));

        if ( 'body.theme-eden-rock-st-barths' && $heroBookingWidgetCalendars.hasClass('active') ) {

            var today = new Date();
            var daySixty = new Date();
            daySixty.setDate(today.getDate() + 59);

            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();
            today = yyyy + '/' + mm + '/' + dd;

            var dd = String(daySixty.getDate()).padStart(2, '0');
            var mm = String(daySixty.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = daySixty.getFullYear();
            daySixty = yyyy + '/' + mm + '/' + dd;

            $.ajax({
                url: 'https://six-middelware.herokuapp.com/api/v1/hotels/63102/calendar',
                type: 'POST',
                contentType: 'application/json; charset=utf-8',
                dataType: 'JSON',
                processData: false,
                headers: {
                    'API-Key': 'VW3bi7E0dnpvGLGcRcXTBC4ai0DLic4iCL4'
                },
                data: JSON.stringify({
                    "start_date": today,
                    "end_date": daySixty,
                    "guests": [
                      {
                        "type": "adult",
                        "count": 2
                      }
                    ],
                    "property_id": null,
                    "currency_code": "EUR",
                    "ada_compliant_rooms": false,
                    "room_id": null,
                    "rate_plan_code": null,
                    "rate_plan_filter_codes": [],
                    "min_rate": 0,
                    "max_rate": 100000,
                    "promo_code": null,
                    "group_code": null
                  }),
                success: function(result){
                    // var response = result.data;

                    // var calendarInputs = $('.hero-booking-widget__calendars .d-table input');
                    // var calendarInputsDates = calendarInputs.each(function() {
                    //     $(this).data('date');
                    // })

                    // console.log(calendarInputs);
                    // console.log(calendarInputsDates);

                }
            })
        }
    });

    $heroBookingWidgetNumbers.on('click', function() {
        $heroBookingWidgetPickers.toggleClass('active');
        $heroBookingWidgetCalendars.removeClass('active');

        $heroBookingWidgetOverlay.toggleClass('active', $heroBookingWidgetPickers.hasClass('active'));
    });

    $heroBookingWidgetOverlay.on('click', function() {
        $heroBookingWidgetPickers.removeClass('active');
        $heroBookingWidgetCalendars.removeClass('active');
        $heroBookingWidgetOverlay.removeClass('active');
    });

    $heroBookingWidget.on('submit', function() {
        $heroBookingWidgetOverlay.removeClass('active');
        $heroBookingWidgetPickers.removeClass('active');
        $heroBookingWidgetCalendars.removeClass('active');
    });

    $heroBookingWidgetCalendarsClose.on('click', function() {
        $heroBookingWidgetCalendars.toggleClass('active');
    })

    // increase or decrease number pickers
    $heroBookingWidgetPlus.on('click', function(e) {
        var $heroBookingWidgetTextbox = $(e.currentTarget).prev('.hero-booking-widget__textbox');

        var currentValue = parseInt($heroBookingWidgetTextbox.val() || '0');
        var max = $heroBookingWidgetTextbox.attr('max');

        if (currentValue + 1 > max) {
            // don't increase if maximum is reached
            return;
        }

        $heroBookingWidgetTextbox.val(currentValue + 1).trigger('change');
    });

    $heroBookingWidgetMinus.on('click', function(e) {
        var $heroBookingWidgetTextbox = $(e.currentTarget).next('.hero-booking-widget__textbox');

        var currentValue = parseInt($heroBookingWidgetTextbox.val() || '0');
        var min = $heroBookingWidgetTextbox.attr('min');

        if (currentValue - 1 < min) {
            // don't decrease if maximum is reached
            return;
        }

        $heroBookingWidgetTextbox.val(currentValue - 1).trigger('change');
    });

    // update summary when selected values change
    $heroBookingWidgetInputs.on('change', function() {
        var rooms = parseInt($heroBookingWidget.find('input[name="rooms"]').val() || '0');
        var adults = parseInt($heroBookingWidget.find('input[name="adult"]').val() || '0');
        var children = parseInt($heroBookingWidget.find('input[name="child"]').val() || '0');

        var pickersSummary =
            rooms +
            ' ' +
            $heroBookingWidgetNumbers.data(rooms === 1 ? 'room' : 'rooms') +
            ', ' +
            adults +
            ' ' +
            $heroBookingWidgetNumbers.data(adults === 1 ? 'adult' : 'adults') +
            (children > 0 ? ', ' + children + ' ' + $heroBookingWidgetNumbers.data(children === 1 ? 'child' : 'children') : '');

        $heroBookingWidgetNumbers.text(pickersSummary);

        var arrival = new Date($heroBookingWidget.find('input[name="arrive"]').val());
        var departure = new Date($heroBookingWidget.find('input[name="depart"]').val());

        var datesSummary =
            (arrival.getTime() > 0 ? arrival.getUTCDate() + '/' + (arrival.getUTCMonth() + 1) + '/' + arrival.getUTCFullYear() : $heroBookingWidgetDates.data('arrival')) +
            ' - ' +
            (departure.getTime() > 0 ? departure.getUTCDate() + '/' + (departure.getUTCMonth() + 1) + '/' + departure.getUTCFullYear() : $heroBookingWidgetDates.data('departure'));

        $heroBookingWidgetDates.text(datesSummary);
    });

    // initialise calendars
    var yesterday = new Date();
    yesterday = new Date(yesterday.getTime() - yesterday.getTimezoneOffset() * 60000); // compensate for timezone difference
    yesterday.setDate(yesterday.getUTCDate() - 1);

    var $heroBookingWidgetArrivalDate = $heroBookingWidget.find('input[name="arrive"]');
    var $heroBookingWidgetDepartureDate = $heroBookingWidget.find('input[name="depart"]');

    var updateCalendarStyles = function(updateValues) {
        if (typeof calendar === 'undefined') {
            // not initialised yet
            return;
        }

        // clear existing classes
        $heroBookingWidgetCalendars.removeClass('selected first-month-start first-month-full first-month-end second-month-start second-month-full second-month-end');

        // get dates
        var dates = calendar.selectedDates;
        var currentDate = calendar.currentDate;
        currentDate = new Date(currentDate.getTime() - currentDate.getTimezoneOffset() * 60000); // compensate for timezone difference
        var firstMonth = currentDate.getUTCMonth();
        var firstYear = currentDate.getUTCFullYear();
        currentDate.setMonth(currentDate.getUTCMonth() + 1);
        var secondMonth = currentDate.getUTCMonth();
        var secondYear = currentDate.getUTCFullYear();

        if (dates.length > 0) {
            var arrivalDate = dates[0];
            arrivalDate = new Date(arrivalDate.getTime() - arrivalDate.getTimezoneOffset() * 60000); // compensate for timezone difference
            var arrivalMonth = arrivalDate.getUTCMonth();
            var arrivalYear = arrivalDate.getUTCFullYear();

            if (arrivalMonth === firstMonth && arrivalYear === firstYear) {
                $heroBookingWidgetCalendars.addClass('first-month-start');
            } else if (arrivalMonth === secondMonth && arrivalYear === secondYear) {
                $heroBookingWidgetCalendars.addClass('second-month-start');
            } else if ((arrivalMonth < firstMonth && arrivalYear === firstYear) || arrivalYear < firstYear) {
                $heroBookingWidgetCalendars.addClass('first-month-full');
            }

            // ensure arrival date is checked (to avoid style issues for long selected period)
            $heroBookingWidgetCalendars.find('[data-date="' + arrivalDate.toJSON() + '"]').prop('checked', true);
        }

        if (dates.length > 1) {
            var departureDate = dates[1];
            departureDate = new Date(departureDate.getTime() - departureDate.getTimezoneOffset() * 60000); // compensate for timezone difference
            var departureMonth = departureDate.getUTCMonth();
            var departureYear = departureDate.getUTCFullYear();

            if (departureMonth === firstMonth && departureYear === firstYear) {
                $heroBookingWidgetCalendars.removeClass('first-month-full');
                $heroBookingWidgetCalendars.addClass('first-month-end');
            } else if (departureMonth === secondMonth && departureYear === secondYear) {
                $heroBookingWidgetCalendars.addClass('second-month-end');
            } else if ((departureMonth > secondMonth && departureYear === secondYear) || departureYear > secondYear) {
                var arrivalDate = dates[0];
                arrivalDate = new Date(arrivalDate.getTime() - arrivalDate.getTimezoneOffset() * 60000); // compensate for timezone difference
                var arrivalMonth = arrivalDate.getUTCMonth();
                var arrivalYear = arrivalDate.getUTCFullYear();

                if ((arrivalMonth < secondMonth && arrivalYear === secondYear) || arrivalYear < secondYear) {
                    $heroBookingWidgetCalendars.addClass('second-month-full');
                }
            } else if ((departureMonth < firstMonth && departureYear === firstYear) || departureYear < firstYear) {
                $heroBookingWidgetCalendars.removeClass('first-month-full');
            }

            $heroBookingWidgetCalendars.addClass('selected');

            // ensure departure date is checked (to avoid style issues for long selected period)
            $heroBookingWidgetCalendars.find('[data-date="' + departureDate.toJSON() + '"]').prop('checked', true);
        }

        // change the booking engine start page if dates have been selected
        $heroBookingWidget.find('input[name="start"]').val(dates.length > 1 ? 'availresults' : '');
    };

    var calendar = new Datepickk({
        container: $heroBookingWidgetCalendars.get(0),
        inline: true,
        minDate: yesterday, //minDate is not inclusive of this date
        range: true,
        months: 2,
        disabledDays: null,
        disabledDates: null,
        lang: $('html').attr('lang'),
        onSelect: function() {
            // get dates
            var dates = calendar.selectedDates;

            // update arrival date field
            var arrivalValue = '';
            var arrivalValueAZDS = '';

            if (dates.length > 0) {
                var arrivalDate = dates[0];

                arrivalDate = new Date(arrivalDate.getTime() - arrivalDate.getTimezoneOffset() * 60000); // compensate for timezone difference

                arrivalValue = arrivalDate.getUTCFullYear() + '-' + ('0' + (arrivalDate.getUTCMonth() + 1)).slice(-2) + '-' + ('0' + arrivalDate.getUTCDate()).slice(-2);

                arrivalValueAZDS = ('0' + (arrivalDate.getUTCMonth() + 1)).slice(-2) + '/' + ('0' + arrivalDate.getUTCDate()).slice(-2) + '/' + arrivalDate.getUTCFullYear();
            }

            $heroBookingWidgetArrivalDate.data('dateAZDS', arrivalValueAZDS);
            $heroBookingWidgetArrivalDate.val(arrivalValue).trigger('change');

            // update departure date field
            var departureValue = '';
            var departureValueAZDS = '';

            if (dates.length > 1) {
                var departureDate = dates[1];

                departureDate = new Date(departureDate.getTime() - arrivalDate.getTimezoneOffset() * 60000); // compensate for timezone difference

                departureValue = departureDate.getUTCFullYear() + '-' + ('0' + (departureDate.getUTCMonth() + 1)).slice(-2) + '-' + ('0' + departureDate.getUTCDate()).slice(-2);

                departureValueAZDS = ('0' + (departureDate.getUTCMonth() + 1)).slice(-2) + '/' + ('0' + departureDate.getUTCDate()).slice(-2) + '/' + departureDate.getUTCFullYear();
            }

            $heroBookingWidgetDepartureDate.data('dateAZDS', departureValueAZDS);
            $heroBookingWidgetDepartureDate.val(departureValue).trigger('change');

            updateCalendarStyles(false);
        },
        onNavigation: function() {
            updateCalendarStyles(true);
        }
    });



    $heroBookingWidget.submit(function(event) {
        var enableAZDRBooking = $heroBookingWidget.data('enableazdrbooking');
        if (enableAZDRBooking) {
            var formAction = $heroBookingWidget.attr('action');
            var arrive = $('#arrive').data('dateAZDS');
            var depart = $('#depart').data('dateAZDS');
            var children = $('#child').val();
            var adults = $('#adult').val();
            var rooms = $('#rooms').val();
            formAction = encodeURI('#/booking/step-2?arrive=' + arrive + '&depart=' + depart + '&rooms=' + rooms + '&adults=' + adults + '&children=' + children);
            $heroBookingWidget.attr('action', formAction);
        }

        if ( $('body.theme-eden-rock-st-barths').length > 0 ) {
            var startDate = $('#arrive').val();
            var endDate = $('#depart').val();
            var adults = $('#adult').val();
            var children = $('#child').val();
            var locale = $('html')[0].lang;

            event.preventDefault();

            bookNow( {
                'startDate': startDate,                      //start_date yyyy/mm/dd
                'endDate': endDate,                         //end_date yyyy/mm/dd
                'guests': [                           //guests (arr)
                    {
                        'type': 'adult',
                        'count': adult
                    },
                    {
                        'type': 'child',
                        'count': child
                    },
                    {
                        'type': 'infants',
                        'count': 0
                    }
                ],
                'propertyId': '',                         //property_id (blank)
                'roomId': '',                     //room_id (blank)
                'ratePlanCode': '',                         //rate_plan_code (blank)
                'promoCode': '',                  //promo_code (blank)
                'groupCode': '',                         //group_code (blank)
                'locale': locale,                     //locale (language)
                'isSingleHotel': true                        //isSingleHotel (bool)
            });
        }
    });

    $heroBookingWidgetCalendars.on('mouseover mouseout', 'label', function() {
        var $input = $(this);
        var isHover = $input.is(':hover');

        // toggle hover state to show selectable date range
        $input
            .closest('.d-tables')
            .find('.hover')
            .removeClass('hover');

        if (calendar.selectedDates.length !== 1) {
            // no dates or all dates selected, don't show potential range
            return;
        }

        var monthTable = $input.closest('.d-table');
        var isFirstTable = monthTable.is(':first-child');

        var selectedDate = $input.prevAll('input[type="checkbox"]:checked');

        if (selectedDate.length > 0) {
            // show hover state from the selected date
            selectedDate
                .first()
                .nextUntil($input)
                .toggleClass('hover', isHover);
        } else {
            var hasFutureSelectedDate = $input.nextAll('input[type="checkbox"]:checked').length > 0;

            if (hasFutureSelectedDate) {
                return;
            }

            var widget = monthTable.closest('.hero-booking-widget__calendars');
            var noMonthsToHover = !widget.hasClass('first-month-full') && !widget.hasClass('first-month-start') && !widget.hasClass('second-month-full') && !widget.hasClass('second-month-start');

            if (noMonthsToHover) {
                return;
            }

            if (!isFirstTable) {
                // show hover state for all dates in this month
                $input.prevAll().toggleClass('hover', isHover);

                // also apply hover state to first month table
                var prevMonthTable = monthTable.prev('.d-table');

                var isStartMonth = widget.hasClass('first-month-start');
                var isFullMonth = widget.hasClass('first-month-full');

                if (isStartMonth) {
                    // hover all dates from the selected date
                    prevMonthTable
                        .find('input[type="checkbox"]:checked')
                        .nextAll()
                        .toggleClass('hover', isHover);
                } else if (isFullMonth) {
                    // hover all dates in this month
                    prevMonthTable.find('label:not(.d-hidden)').toggleClass('hover', isHover);
                }
            } else {
                var isSecondStartMonth = widget.hasClass('second-month-start');

                if (!isSecondStartMonth) {
                    // hover dates up until hovered date cell
                    $input.prevAll().toggleClass('hover', isHover);
                }
            }
        }
    });
}

function initMaps() {
    // trigger event that tells other functions the maps can be loaded
    $(document).trigger('google_maps_callback');
}

function initPipeSeparators(e) {
    $('.js-pipe-separator').each(function () {
        // get list offset position
        var $list = $(this);
        var $listItems = $list.find('li');
        var currentLineOffset = $list.offset().top;

        // loop through list items and add extra class when new line is started
        $listItems.removeClass('no-pipe-separator').each(function (i) {
            var $li = $(this);
            var liOffset = $li.offset().top;

            if (liOffset > currentLineOffset && i > 0) {
                // new line, add class to previous element to hide pipe separator
                $($listItems.get(i - 1)).addClass('no-pipe-separator');
                // update line offset
                currentLineOffset = liOffset;
            }
        });
    });

    // during first call, register resize event handler to update lists
    if (!e) {
        $(window).on('resize', initPipeSeparators);
    }
}

function animateBorders() {}

function initAnimateInView() {
    $(window).on('DOMContentLoaded load resize scroll', animateElementsInView);
    animateElementsInView();
}

function animateElementsInView() {
    $('[data-animate-in-view]').each(function () {
        //console.log($(this));
        if (isElementInViewport($(this))) {
            $(this).addClass($(this).data('animate-in-view'));
        }
    });
}

// https://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
function isElementInViewport(el) {
    //special bonus for those using jQuery
    if (typeof jQuery === 'function' && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();

    return rect.top <= $(window).scrollTop() + window.innerHeight;
}

function switchGridType() {
    $('#carousel button').on('click', function () {
        $('.grid-tile').removeClass('active');
        $('.grid-listing').removeClass('active');
        $('.grid-location').removeClass('active');

        $('.grid-carousel').addClass('active');
        $('#carousel button').addClass('active');

        $('#tile button').removeClass('active');
        $('#location button').removeClass('active');
        $('#listing button').removeClass('active');
        $('.grid-carousel').foundation();

        $('.filter-controls').css('display', 'none');

        $('.grid-carousel__item-img.slick-initialized').slick('slickGoTo', 0, false);
        $('.grid-carousel__carousel.slick-initialized').slick('refresh');

        if ( $(window).outerWidth() < 640 ) {
            $('.grid .filter-mobile-accordion').css('display', 'block');
        }
    });

    $('#tile button').on('click', function () {
        $('.grid-carousel').removeClass('active');
        $('.grid-listing').removeClass('active');
        $('.grid-location').removeClass('active');

        $('.grid-tile').addClass('active');
        $('#tile button').addClass('active');

        $('#carousel button').removeClass('active');
        $('#location button').removeClass('active');
        $('#listing button').removeClass('active');
        $('.grid-tile').foundation();

        $('.filter-controls').css('display', 'block').css('opacity', 1).css('pointer-events', 'all');

        $('.grid-tile__item-img.slick-initialized').slick('slickGoTo', 0, false);
        $('.grid-tile__item-img.slick-initialized').slick('refresh');

        if ( $(window).outerWidth() < 640 ) {
            $('.grid .filter-mobile-accordion').css('display', 'block');
        }

        $(window).trigger('resize');
    });

    $('#listing button').on('click', function () {
        $('.grid-carousel').removeClass('active');
        $('.grid-tile').removeClass('active');
        $('.grid-location').removeClass('active');

        $('.grid-listing').addClass('active');
        $('#listing button').addClass('active');

        $('#carousel button').removeClass('active');
        $('#location button').removeClass('active');
        $('#tile button').removeClass('active');
        $('.grid-listing').foundation();

        $('.filter-controls').css('display', 'block').css('opacity', 1).css('pointer-events', 'all');

        $('.grid-listing__item-img.slick-initialized').slick('slickGoTo', 0, false);
        $('.grid-listing__item-img.slick-initialized').slick('refresh');

        if ( $(window).outerWidth() < 640 ) {
            $('.grid .filter-mobile-accordion').css('display', 'block');
        }

        $(window).trigger('resize');
    });

    $('#location button').on('click', function () {
        $('.grid-carousel').removeClass('active');
        $('.grid-listing').removeClass('active');
        $('.grid-tile').removeClass('active');

        $('.grid-location').addClass('active');
        $('#location button').addClass('active');

        $('#carousel button').removeClass('active');
        $('#tile button').removeClass('active');
        $('#listing button').removeClass('active');
        $('.grid__location').foundation();

        $('.filter-controls').css('display', 'block').css('opacity', 0).css('pointer-events', 'none');

        if ( $(window).outerWidth() < 640 ) {
            $('.grid .filter-mobile-accordion').css('display', 'none');
            $('.grid-location.active').css('padding-top', '15px');
        }


        $('.destinations-map__popup-slider.slick-initialized').slick('slickGoTo', 0, false);
        $('.destinations-map__popup-slider.slick-initialized').slick('refresh');
    });

    setTimeout(function () {
        $(window).trigger('resize');
    }, 200);
}

function initGrid() {
    if ($('.grid .filter-controls').length < 1) {
        $('.grid__view').css('position', 'relative');
    }




    if ($('.grid-carousel.active').length > 0) {
        $('#carousel button').addClass('active');
    } else {
        if ($('.grid-listing.active').length > 0) {
            $('#listing button').addClass('active');
        } else {
            if ($('.grid-tile.active').length > 0) {
                $('#tile button').addClass('active');
            } else {
                if ($('.grid-location.active').length > 0) {
                    $('#location button').addClass('active');

                    if ( $(window).outerWidth() < 640 ) {
                        $('.grid .filter-mobile-accordion').css('display', 'none');
                        $('.grid-location.active').css('padding-top', '15px');
                    }
                }
            }
        }
    }

    // Add space above the carousel grid option if the title is not set
    $('.grid-carousel').each(function (i, el) {
        var $el = $(el);

        var hasTitle = ($el.find('.grid-carousel__title').length > 0);
        var hasFilters = ($el.closest('.grid').find('.filter-controls').length > 0);

        if (!hasTitle && hasFilters) {
            $el.css('padding-top', '79px');
        }
    })

    // For the gallery grid show/hide modal images depending on filter changes
    $('.grid--gallery [data-filter]').on('filter-changed', function() {
        var gridFilter = $(this);

        setTimeout(function () {
            var galleryModal;

            gridFilter.find('img[data-open]').each(function (i, img) {
                var $img = $(img);
                var slideIndex = $img.data('slide-index');
                var isVisible = $img.is(':visible');

                galleryModal = $('#' + $img.data('open'));

                galleryModal.find('[data-slide-index="' + slideIndex + '"]').toggleClass('hide', !isVisible);
            });
        }, 500);
    });
    var galleryGridOffset;
    $('.grid-contact-tile__item-img-slide img').on('click',function() {
        galleryGridOffset = $(window).scrollTop();
    })

    $('.gallery-modal__button').on('click', function() {
        setTimeout(function() {
            $(window).scrollTop(galleryGridOffset);
        },200);
    })


    if ( (window.location.hash) ) {
        var windowHash = window.location.hash.substring(1);
        var categoryButtons = [];

        if ( $(window).outerWidth() >= 640 ) {

            $('.grid .show-for-medium .filter-controls button').each(function(i) {
                if ( i == 0 ) {
                    return;
                } else {
                    var getCategory = $(this).data('filter-category').replace(' ', '-');
                    if ( getCategory !== undefined ) {
                        getCategory = getCategory.toLowerCase();
                        categoryButtons.push(getCategory);
                    }
                }
            })

            if ( categoryButtons.indexOf(windowHash) > -1 ) {
                var chosenCategoryButton = $('.grid .show-for-medium .filter-controls button[data-filter-category="'+ windowHash.replace('-', ' ') + '"]');

                if ( chosenCategoryButton.length > 0 ) {
                    chosenCategoryButton[0].click();
                }
            }

        } else {

            $('.grid .filter-mobile-accordion .filter-controls button').each(function(i) {
                if ( i == 0 ) {
                    return;
                } else {
                    var getCategory = $(this).data('filter-category').replace(' ', '-');
                    if ( getCategory !== undefined ) {
                        getCategory = getCategory.toLowerCase();
                        categoryButtons.push(getCategory);
                    }
                }
            })

            if ( categoryButtons.indexOf(windowHash) > -1 ) {
                var chosenCategoryButton = $('.grid .filter-mobile-accordion .filter-controls button[data-filter-category="'+ windowHash.replace('-', ' ') + '"]');

                if ( chosenCategoryButton.length > 0 ) {
                    chosenCategoryButton[0].click();
                }
            }
        }
    }
}

function initSliders() {
    var dataSlides = $('.grid-carousel__carousel').data('slidesToShow')
    var carouselSlidesToShow = typeof dataSlides === 'undefined' || typeof dataSlides !== 'number' ? 3 : dataSlides

    if (carouselSlidesToShow < 3 && carouselSlidesToShow > 0 ) {
        $('.grid-carousel').css('max-width', '900px');
    }

    $('.grid-carousel__carousel').slick({
        infinite: true,
        slidesToShow: carouselSlidesToShow,
        slidesToScroll: 1,
        arrows: true,
        draggable: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });

    $(
        '.venue-grid__item-img, \
         .offers__grid-item-img, \
         .hotel-offers-landing__tabs-item-img-slider, \
         .grid-carousel__item-img, \
         .grid-tile__item-img, \
         .grid-contact-tile__item-img, \
         .grid-listing__item-img, \
         .two-images__small-img, \
         .two-images__big-img, \
         .text-image__img, \
         .feature-text-image-paged__slide-image, \
         .small-gallery__small-slider \
         .small-gallery__medium-slider \
         .small-gallery__large-slider \
         .gallery-modal .slider'
    ).each(function (i, el) {
        var $el = $(el);

        // only initialise slider when there is more than 1 item inside it
        if ($el.children().length > 1) {
            $el.slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
            });
        }
    });

    $('.events__carousel').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        draggable: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });

    function setEqualheight() {
        var contents = $("[data-custom-equalizer='grid-paragraph']");
        var buttons = $("[data-custom-equalizer='grid-cta']");
        var titles= $("[data-custom-equalizer='grid-title']");
        var items= $("[data-custom-equalizer='grid-item']");

        function getMaxHeight (array) {

          var heightsArr = [];

          array.each(function () {
            heightsArr.push($(this).height());
          });
          var arr = Object.keys(heightsArr).map(function (key) {
            return heightsArr[key];
          });

          var max = Math.max.apply(null, arr);
          return max
        }

        var maxContent = getMaxHeight(contents)
        var maxButton = getMaxHeight(buttons)
        var maxTitles = getMaxHeight(titles)
        var maxItems = getMaxHeight(items)

        contents.each(function () {
          $(this).css("height", maxContent);
        });
        buttons.each(function () {
          $(this).css("height", maxButton);
        })
        titles.each(function () {
            $(this).css("height", maxTitles);
          })
        items.each(function () {
            $(this).css("height", maxItems);
        })
      }


      setEqualheight()
      $(window).on('resize', setEqualheight);

    $('.grid-carousel__carousel').on('afterChange', function (event, slick, currentSlide, nextSlide) {
        setEqualheight()
    });

    $('.events-carousel__carousel').on('afterChange', function (event, slick, currentSlide, nextSlide) {
        setEqualheight()
    });

    // all sliders that use the data-slick attribute to set their specific settings
    $('[data-slick]').slick();
    setTimeout(function(){
        Foundation.reInit('equalizer');
        $(window).trigger("resize");
    }, 1000);

}

function initSubMenuDropdown() {
    $('.hotel-header__sub-nav > ul > li').each(function () {
        if ($(this).has('ul .hotel-header__sub-nav-dropdown-wrapper')) {
            $(this).hover(function () {
                $(this).addClass('show-dropdown');
            });

            $(this).mouseleave(function () {
                $(this).removeClass('show-dropdown');
            });
        }
    });

    $('.off-canvas__nav > .vertical-center-table > .vertical-center-row > .vertical-center-cell > ul > li').each(function () {
        if ($(this).has('ul .off-canvas__sub-nav-dropdown-wrapper')) {
            $(this).hover(function () {
                $(this).addClass('show-dropdown');
            });

            $(this).mouseleave(function () {
                $(this).removeClass('show-dropdown');
            });

            $(this).on('click', function (event) {
                if (!Foundation.MediaQuery.atLeast('medium')) {
                    return;
                }

                if ($(this).children('ul').length > 0 && !$(this).hasClass('show-dropdown')) {
                    // on mobile when click, display dropdown with close button on first click (normal link works on second click)
                    $(this).addClass('show-dropdown show-dropdown--with-close');

                    event.preventDefault();
                    return false;
                }
            });

            $(this).find('.off-canvas__sub-nav-dropdown-close').on('click', function (event) {
                // close sub nav dropdown when clicked on mobile
                $(this).closest('.show-dropdown--with-close').removeClass('show-dropdown show-dropdown--with-close');

                event.preventDefault();
                return false;
            });
        }
    });

    if ($('body.hotel.no-hero').length < 1) {

        $('.hotel-header, .hotel-header__sub-nav').mouseover(function () {
            $('.hotel-header').addClass('fixed');

            $('.hotel-header__logo .logo').css('display', 'none');
            $('.hotel-header__logo .logo-dark').css('display', 'block');
            $('.hotel-header__logo-group .logo').css('display', 'none');
            $('.hotel-header__logo-group .logo-dark').css('display', 'block');
        });

        $('.hotel-header, .hotel-header__sub-nav').mouseout(function () {
            $('.hotel-header').removeClass('fixed');

            if ($('.hotel-header.opened').length < 1) {
                $('.hotel-header__logo .logo').css('display', 'block');
                $('.hotel-header__logo .logo-dark').css('display', 'none');
                $('.hotel-header__logo-group .logo').css('display', 'block');
                $('.hotel-header__logo-group .logo-dark').css('display', 'none');
            } else {
                $('.hotel-header').addClass('fixed');
            }
        });

        // fix for iOS devices where a first touch will trigger the hover state, so needs an extra trigger to handle the click in one go.
        $('.hotel-header, .hotel-header__sub-nav').find('button, a').on('touchstart', function () {
            if (!$('.hotel-header').hasClass('fixed')) {
                this.click();
            }
        });
    }

    // check that all links fit on one line or move them to a dropdown
    $('.hotel-header__sub-nav')
        .on('resizeme.zf.trigger', function () {
            var $subNav = $(this);

            if (!$subNav.is(':visible')) {
                // nothing to check
                return;
            }

            var $subNavMore = $subNav.find('.hotel-header__sub-nav-more');
            var $subNavLinks = $subNav.find('> ul > li:not(.hotel-header__sub-nav-more)');
            var $subNavMoreDropdown = $subNavMore.find('.hotel-header__sub-nav-dropdown');

            // hide more link and clear any copied links from the more dropdown
            $subNavMore.addClass('hotel-header__sub-nav-more--active');

            var subNavMoreWidth = $subNavMore.outerWidth();
            subNavMoreWidth += parseFloat($subNavMore.css('margin-left'));
            subNavMoreWidth += parseFloat($subNavMore.css('margin-right'));

            $subNavMore.removeClass('hotel-header__sub-nav-more--active');
            $subNavMoreDropdown.children().remove();

            // show any hidden links in the sub nav
            $subNavLinks.removeClass('hidden');

            // loop through all links in the sub nav until they no longer fit and then copy them to the dropdown
            var subNavWidth = $subNav.outerWidth() - subNavMoreWidth;
            var totalLinkWidth = 0;

            $subNavLinks.each(function (index, link) {
                var $link = $(link);

                var linkWidth = $link.outerWidth();
                linkWidth += parseFloat($link.css('margin-left'));
                linkWidth += parseFloat($link.css('margin-right'));
                linkWidth += 6; // Adding a little extra safety margin due to inline-block style

                totalLinkWidth += linkWidth;

                if (totalLinkWidth >= subNavWidth) {
                    var linkClone = $link.clone();

                    // remove any child sub menu as we can't have nested dropdowns
                    linkClone.children('ul').remove();

                    var wrapperPosition = ($subNavMoreDropdown.children().length % 2 == 0 ? 'left' : 'right');

                    var dropdownLinkWrapper = $('<div class="hotel-header__sub-nav-dropdown-' + wrapperPosition + ' cell small-3' + (wrapperPosition === 'left' ? ' small-offset-3' : '') + '"></div>');

                    dropdownLinkWrapper.append(linkClone);

                    $subNavMoreDropdown.append(dropdownLinkWrapper);

                    $subNavMore.addClass('hotel-header__sub-nav-more--active');

                    // hide the original link
                    $link.addClass('hidden');
                }
            });
        })
        .trigger('resizeme.zf.trigger');
}

function initOffCanvas() {
    $('.hotel-off-canvas__primary > ul > li').has('ul').addClass('sub-nav');

    var count = 0;
        $('.hotel-off-canvas__primary > ul > li').each(function () {
            if ($(this).find('ul').length > 0) {
                var $landingLink = $(this).find('a').first();

                $('.hotel-off-canvas__inner').append(
                    '<nav id="hotel-off-canvas__sub-' +
                        count +
                        '" class="hotel-off-canvas__sub"><button class="hotel-off-canvas__sub-close" type="button">Close sub menu</button><ul><li><a href="' +
                        $landingLink.attr('href') +
                        '">' +
                        $landingLink.text() +
                        ' Overview</a></li>' +
                        $(this).find('ul').html() +
                        '</ul></nav>'
                );

                $landingLink.data('open-sub', count);

                count++;
            }
        });

    // Generate the dynamic sub nav
    $('#offCanvas').on('opened.zf.offcanvas', function () {
        $('body').addClass('no-scroll');

        var count = 0;
        $('.hotel-off-canvas__primary > ul > li').each(function () {
            if ($(this).find('ul').length > 0) {
                var $landingLink = $(this).find('a').first();

                $('.hotel-off-canvas__inner').append(
                    '<nav id="hotel-off-canvas__sub-' +
                        count +
                        '" class="hotel-off-canvas__sub"><button class="hotel-off-canvas__sub-close" type="button">Close sub menu</button><ul><li><a href="' +
                        $landingLink.attr('href') +
                        '">' +
                        $landingLink.text() +
                        ' Overview</a></li>' +
                        $(this).find('ul').html() +
                        '</ul></nav>'
                );

                $landingLink.data('open-sub', count);

                count++;
            }
        });
    });

    // Open the dynamic sub nav only for items which have it
    $('.hotel-off-canvas__primary > ul > li > a').on('click', function () {
        if (typeof $(this).data('open-sub') !== 'undefined') {
            var subId = $(this).data('open-sub');

            $('.hotel-off-canvas__sub--is-open').removeClass('hotel-off-canvas__sub--is-open');

            $('#hotel-off-canvas__sub-' + subId).addClass('hotel-off-canvas__sub--is-open');

            return false;
        }
    });

    $(document).on('click', '.hotel-off-canvas__sub-close', function () {
        $(this).parent().removeClass('hotel-off-canvas__sub--is-open');
    });

    $('#offCanvas').on('closed.zf.offcanvas', function () {
        $('body').removeClass('no-scroll');

        $('.hotel-off-canvas__sub--is-open').removeClass('hotel-off-canvas__sub--is-open');
        $('.hotel-off-canvas__sub').remove();
    });
}

function openBurgerMenu() {
    $('.header__primary-nav-burger .button').click(function (e) {
        $('#offCanvas').foundation('open', e, $(this));

        $('.header').toggleClass('opened');
        $('.sticky').addClass('fixed');
        $('.header__logo .logo').css('display', 'none');
        $('.header__logo .logo-dark').css('display', 'block');

        $('.header__primary-nav-burger .button').css('display', 'none');
        $('.header__primary-nav-burger .close-button').css('display', 'block');
    });

    $('.hotel-header__primary-nav-burger .button').click(function (e) {
        $('#offCanvas').foundation('open', e, $(this));

        $('.hotel-header').toggleClass('opened');
        $('.sticky').addClass('fixed');
        $('.hotel-header__logo .logo').css('display', 'none');
        $('.hotel-header__logo .logo-dark').css('display', 'block');
        $('.hotel-header__logo-group .logo').css('display', 'none');
        $('.hotel-header__logo-group .logo-dark').css('display', 'block');

        $('.hotel-header__primary-nav-burger .button').css('display', 'none');
        $('.hotel-header__primary-nav-burger .close-button').css('display', 'block');
    });
}

function closeBurgerMenu() {
    $('.header__primary-nav-burger .close-button').click(function () {
        $('#offCanvas').foundation('close');

        if ($('body.no-hero').length > 0) {
            $('.header').toggleClass('opened');
            $('.header__logo .logo').css('display', 'none');
            $('.header__logo .logo-dark').css('display', 'block');
            $('.header__primary-nav-burger .close-button').css('display', 'none');
            $('.header__primary-nav-burger .button').css('display', 'block');
        } else {
            if ($(document).scrollTop() == 0) {
                $('.header').toggleClass('opened');
                $('.sticky').removeClass('fixed');
                $('.header__logo .logo').css('display', 'block');
                $('.header__logo .logo-dark').css('display', 'none');

                $('.header__primary-nav-burger .close-button').css('display', 'none');
                $('.header__primary-nav-burger .button').css('display', 'block');
            } else {
                $('.header').toggleClass('opened');
                $('.header__primary-nav-burger .close-button').css('display', 'none');
                $('.header__primary-nav-burger .button').css('display', 'block');
            }
        }
    });

    $('.hotel-header__primary-nav-burger .close-button').click(function () {
        $('#offCanvas').foundation('close');

        if ($('body.no-hero').length > 0) {
            $('.hotel-header').toggleClass('opened');
            $('.hotel-header__primary-nav-burger .close-button').css('display', 'none');
            $('.hotel-header__primary-nav-burger .button').css('display', 'block');
        } else {
            if ($(document).scrollTop() == 0) {
                $('.hotel-header').toggleClass('opened');
                $('.sticky').removeClass('fixed');
                $('.hotel-header__logo .logo').css('display', 'block');
                $('.hotel-header__logo .logo-dark').css('display', 'none');
                $('.hotel-header__logo-group .logo').css('display', 'block');
                $('.hotel-header__logo-group .logo-dark').css('display', 'none');

                $('.hotel-header__primary-nav-burger .close-button').css('display', 'none');
                $('.hotel-header__primary-nav-burger .button').css('display', 'block');
            } else {
                $('.hotel-header').toggleClass('opened');
                $('.hotel-header__primary-nav-burger .close-button').css('display', 'none');
                $('.hotel-header__primary-nav-burger .button').css('display', 'block');
            }
        }
    });

    if ('.off-Canvas.is-closed'.length > 0) {
        $('.js-off-canvas-overlay').on('click', function () {
            $('#offCanvas').foundation('close');

            if ($('body.no-hero').length > 0) {
                $('.header').toggleClass('opened');
                $('.header__logo .logo').css('display', 'none');
                $('.header__logo .logo-dark').css('display', 'block');
                $('.header__primary-nav-burger .close-button').css('display', 'none');
                $('.header__primary-nav-burger .button').css('display', 'block');
            } else {
                if ($(document).scrollTop() == 0) {
                    $('.header').toggleClass('opened');
                    $('.sticky').removeClass('fixed');
                    $('.header__logo .logo').css('display', 'block');
                    $('.header__logo .logo-dark').css('display', 'none');

                    $('.header__primary-nav-burger .close-button').css('display', 'none');
                    $('.header__primary-nav-burger .button').css('display', 'block');
                } else {
                    $('.header').toggleClass('opened');
                    $('.header__primary-nav-burger .close-button').css('display', 'none');
                    $('.header__primary-nav-burger .button').css('display', 'block');
                }
            }
        });
    }
}

function initSticky() {
    if( $('.hero .video-wrapper').length >= 1 ) {
        $('body').addClass('video-slider');

        // $('.video-wrapper').fadeIn(2000);
    }
    $(window).scroll(function () {

        if ($('body.no-hero').length == 0 && $('hotel-header.opened').length == 0) {
            if ($('.header.opened').length == 0) {
                var sticky = $('.sticky'),
                scrollOffset = $(window).scrollTop(),
                isPortrait = $(window).outerHeight() > $(window).outerWidth();

                if (scrollOffset >= 5 ) {
                    sticky.addClass('fixed');

                    if (Foundation.MediaQuery.atLeast('medium') && !isPortrait) {
                        $('.header__logo .logo').css('display', 'none');
                        $('.header__logo .logo-dark').css('display', 'block');

                        $('.hotel-header__logo .logo').css('display', 'none');
                        $('.hotel-header__logo .logo-dark').css('display', 'block');
                        $('.hotel-header__logo-group .logo').css('display', 'none');
                        $('.hotel-header__logo-group .logo-dark').css('display', 'block');
                    }
                } else {
                    sticky.removeClass('fixed');

                    if (Foundation.MediaQuery.atLeast('medium') && !isPortrait) {
                        $('.header__logo .logo').css('display', 'block');
                        $('.header__logo .logo-dark').css('display', 'none');
                    }

                    if ($('.hotel-header.opened').length < 1) {
                        if (Foundation.MediaQuery.atLeast('medium') && !isPortrait) {
                            $('.hotel-header__logo .logo').css('display', 'block');
                            $('.hotel-header__logo .logo-dark').css('display', 'none');
                            $('.hotel-header__logo-group .logo').css('display', 'block');
                            $('.hotel-header__logo-group .logo-dark').css('display', 'none');
                        }
                    } else {
                        sticky.addClass('fixed');
                    }
                }
            }
        } else {
            return;
        }
    });

    if ($('body.no-hero').length < 1) {
        $('.header').mouseover(function () {
            $('.header').addClass('fixed');

            $('.header__logo .logo').css('display', 'none');
            $('.header__logo .logo-dark').css('display', 'block');
            $('.header__logo-group .logo').css('display', 'none');
            $('.header__logo-group .logo-dark').css('display', 'block');
        });

        $('.header').mouseout(function () {
            $('.header').removeClass('fixed');

            if ($('.header.opened').length < 1) {
                $('.header__logo .logo').css('display', 'block');
                $('.header__logo .logo-dark').css('display', 'none');
                $('.header__logo-group .logo').css('display', 'block');
                $('.header__logo-group .logo-dark').css('display', 'none');
            } else {
                $('.header').addClass('fixed');
            }
        });

        // fix for iOS devices where a first touch will trigger the hover state, so needs an extra trigger to handle the click in one go.
        $('.header').find('button, a').on('touchstart', function () {
            if (!$('.header').hasClass('fixed')) {
                this.click();
            }
        });
    }


}

function initVerticalCenter() {
    $('.vertical-center').each(function () {
        $(this).wrapInner('<div class="vertical-center-table"><div class="vertical-center-row"><div class="vertical-center-cell"></div></div></div>');
    });
}

function openLanguageDropdown() {
    $('.selected-language').click(function () {
        $('.language-list').toggleClass('open');
    });
}

function initHero() {
    $('.hero-slider')
        .slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: true,
            infinite: true,
            prevArrow: '<button type="button" class="control-arrow control-prev control-prev--light"></button>',
            nextArrow: '<button type="button" class="control-arrow control-next control-next--light"></button>',
        })
        .on('afterChange', function (e) {
            var slick = $(this).slick('getSlick');

            // var autoplayVideos = $(window).outerWidth() >= 900;

            // slick.$slides.each(function (i, slide) {
            //     if (i === slick.currentSlide) {
            //         // start video on current slide
            //         if (autoplayVideos) {
            //             $(slide)
            //                 .find('.hero-slider__video')
            //                 .each(function (j, el) {
            //                     el.play();
            //                 });
            //         }
            //     } else {
            //         // pause video on hidden slide
            //         $(slide)
            //             .find('.hero-slider__video')
            //             .each(function (j, el) {
            //                 el.pause();
            //             });
            //     }
            // });
        })
        .trigger('afterChange');

    // $('.hero-slider__video source').each(function (i, el) {
    //     var $el = $(el);

    //     $el.attr('src', $el.data('src'));
    // });

    // $(window).on('resize.video-margin', function () {
    //     var setVideoMargin = $(window).outerWidth() < 1024;

    //     $('.hero-slider__video').each(function (i, el) {
    //         var $el = $(el);

    //         if (setVideoMargin) {
    //             var containerHeight = $el.parent().outerHeight();

    //             // assume 16:9 video
    //             var videoWidth = (containerHeight / 9) * 16;

    //             $el.css('margin-left', '-' + videoWidth / 2 + 'px');
    //         } else {
    //             $el.css('margin-left', '');
    //         }
    //     });
    // }).trigger('resize.video-margin');

    $('[data-hero-id]')
        .on('mouseenter', function () {
            $('#' + $(this).data('hero-id')).addClass('active');
        })
        .on('mouseleave', function () {
            $('#' + $(this).data('hero-id')).removeClass('active');
        });

    $('.hero .scroll').on('click', function () {
        var nextElement = $(this).closest('.hero').next();

        $('html, body').animate({
            scrollTop: nextElement.offset().top - 150,
        });
    });

    // if ($('.hero--video').length > 0) {
    //     scaleVideos();
    //     $(window).on('resize', scaleVideos);
    // }

    // function scaleVideos() {
    //     $('.hero--video iframe').each(function () {
    //         coverParent(this, window);
    //     });
    // }

    function coverParent(element, parent) {
        var parentWidth = $(parent).innerWidth();
        var parentHeight = $(parent).innerHeight();

        var $element = $(element);

        var elementWidth = $element.attr('width');
        var elementHeight = $element.attr('height');

        // Aspect ratio = height / width
        var parentRatio = parentHeight / parentWidth;
        var videoRatio = elementHeight / elementWidth;

        var newWidth = (elementWidth / elementHeight) * parentHeight;
        var newHeight = (elementHeight / elementWidth) * parentWidth;

        if (parentRatio > videoRatio) {
            // set video height to the viewport, and extend the video beyond the viewport width, i.e. portrait viewport
            $element.css('height', parentHeight);
            $element.css('width', newWidth);
            $element.css('left', '-' + (newWidth - parentWidth) / 2 + 'px');
            $element.css('top', '0');
        } else if (parentRatio < videoRatio) {
            // set video width to the viewport, and extend the video beyond the viewport height, i.e. landscape viewport
            $element.css('width', parentWidth);
            $element.css('height', newHeight);
            $element.css('left', '0');
            $element.css('top', '-' + (newHeight - parentHeight) / 2 + 'px');
        } else {
            // set the video width AND height to the viewport size, i.e. viewport is the same ratio as the video
            $element.css('height', parentHeight);
            $element.css('width', parentWidth);
            $element.css('left', '0');
            $element.css('top', '0');
        }
    }

    if ( $('.hero').length > 0 ) {
        positionScrollAndPagination();
        $(window).on('resize', positionScrollAndPagination);
    }

    function positionScrollAndPagination() {
        if ( $(window).innerHeight() < $('.hero').outerHeight() ) {
            // Window is shorter than the hero slider so move these elements above the
            var verticalOffset = parseInt( $('.hero').outerHeight() - $(window).innerHeight() );

            if ( !isNaN(verticalOffset) ) {

                // Compensate for the hero booking widget
                if ( $('.hero-booking-widget').length > 0 ) {
                    verticalOffset -= $('.hero-booking-widget').outerHeight();
                }

                $('.hero .scroll').css('transform', 'translate(-50%, -' + verticalOffset + 'px)');
                $('.hero-slider .slick-dots, h1.titleDefault').css('transform', 'translateY(-' + verticalOffset + 'px)');
            }
        } else {
            // Reset to default styles if the window is tall enough
            $('.hero .scroll, .hero-slider .slick-dots, h1.titleDetault').attr('style', '');
        }
    }


    function handleVimeoPlayer() {
        if (window.innerWidth > 640) {
            var $iframeDesktop = $('.desktop-hero-video');
            var player = new Vimeo.Player($iframeDesktop);

            player.getPaused().then(function(paused) {
              if (!paused) {
                $('.video-wrapper .video-placeholder').addClass('hide')
              } else {
                player.play();
                $('.video-wrapper .video-placeholder').addClass('hide')
              }
            });


        } else {
            $('.hero-slider__mobileplay').click(function() {
                var iframeMobile = $('iframe.mobile-hero-video');
                var playerMobile = new Vimeo.Player(iframeMobile);
                playerMobile.getPaused().then(function(paused) {
                    if ( paused == true ) {
                        playerMobile.play();
                        $('.hero-slider__mobileplay').hide();
                        $('.video-wrapper .video-placeholder').addClass('hide')
                    }
                });
            });
        }
    }

    if ($('.video-wrapper').length > 0) {
        handleVimeoPlayer()
    }





}

function initAccordions() {
    if ($('.accordion').length > 0) {
        $('.accordion').foundation('up', $('.accordion .accordion-content'));
    }

    $('.new-accordion__item').accordiom({
        showFirstItem: false,
        buttonBelowContent: false,
    });

    $('.filter-mobile-accordion').accordiom({
        showFirstItem: false,
        buttonBelowContent: false,
    });

    $('.offers-filter-accordion').each(function (index, filterAccordion) {
        var $filterAccordion = $(filterAccordion);

        var hasLocationFilterOnly = $filterAccordion.children('.accordionContent').length === $filterAccordion.children('.accordionContent.location-filter-controls').length;

        $filterAccordion.accordiom({
            showFirstItem: hasLocationFilterOnly,
            buttonBelowContent: false,
        });
    });

    $('.feature-text__accordion').accordiom({
        showFirstItem: false,
        buttonBelowContent: true,
    });

    $('.feature-text-paged__accordion').accordiom({
        showFirstItem: false,
        buttonBelowContent: false,
    });

    $('.info-tab__accordion').accordiom({
        showFirstItem: false,
        buttonBelowContent: true,
        afterChange: function (btn) {
            setTimeout(function () {
                // resize tab elements after accordion change
                var dataEqualizer = $(btn).closest('[data-equalizer]');

                if (dataEqualizer.length > 0) {
                    dataEqualizer.find('[data-equalizer-watch]').css('height', 'auto');
                    Foundation.reInit(dataEqualizer);
                }
            }, 500);
        }
    });

    $('.info-tab__item-container .accordionButton, .info-tab__service-accordion .accordionButton, .feature-text-paged__accordion .accordionButton, .feature-text__accordion .accordionButton').click(function () {
        var $heading = $(this).find('h4');
        var moreText = $heading.data('more-text');
        var lessText = $heading.data('less-text');

        if ($heading.text() === moreText) {
            $heading.text(lessText);
        } else {
            $heading.text(moreText);
        }
    });

    $('.info-tab__item-container .accordionButton').on('click', function () {
        $(this)
            .toggleClass('on')
            .closest('.info-tab__item-container').toggleClass('info-tab__item-container--only-show-first');
    });

    $('.info-tab__service-accordion').accordiom({
        showFirstItem: false,
        buttonBelowContent: true
    });

    $('.blog-suggestions__load-more .accordionButton').on('click', function () {
        setTimeout(function () {
            Foundation.reInit('equalizer');
        },500)
    })


    if ( $(window).outerWidth() < 640 ) {
        $('.blog-filter-accordion').accordiom({
            showFirstItem: false
        })
    } else {
        $('.blog-filter-accordion').accordiom({
            showFirstItem: true
        })
    }

    $('.blog-off-canvas__nav-accordion').accordiom({
        showFirstItem: false
    })

    $('.blog-suggestions__load-more').accordiom({
        showFirstItem: false,
        buttonBelowContent: true
    })

    $('.blog-comments__reply-show-hide').accordiom({
        showFirstItem: false
    })

    $('.contact-travel__item-operators-mobile').accordiom({
        showFirstItem: false
    })
}

function initTabs() {
    // reinit any equalizers inside a tab when the tab changes
    $('[data-tabs]').on('change.zf.tabs', function (e, tab, tabContent) {
        var dataEqualizer = tabContent.first('[data-equalizer-watch]').closest('[data-equalizer]');

        if (dataEqualizer.length > 0) {
            Foundation.reInit(dataEqualizer);
        }
    });
}

function getMediaElementPlayer(videoElement) {
    var mediaElementPlayer = videoElement.data('mediaelementplayer');

    if (typeof mediaElementPlayer !== 'undefined') {
        return mediaElementPlayer;
    } else {
        // some browsers need a different way of getting the player (e.g. Safari on MacOS)
        var mediaElementWrapperId = videoElement.parent('mediaelementwrapper').attr('id');

        for (var player in mejs.players) {
            if (mejs.players.hasOwnProperty(player)) {
                var playerId = $(mejs.players[player].media).attr('id');

                if (mediaElementWrapperId === playerId) {
                    return mejs.players[player];
                }
            }
        }
    }
}

var currentDeviceSize = '';

function initVideoHeaders() {

  if ( $(window).outerWidth() <= 640 ) {
    currentDeviceSize = 'small';
  } else {
    currentDeviceSize = 'medium';
  }
  $(window).on('resize', function () {
    if ( $(window).outerWidth() <= 640 ) {
      var newDeviceSize = 'small';
    } else {
      var newDeviceSize = 'medium';
    }
    if (currentDeviceSize !== newDeviceSize) {
      location.reload();
      return false;
    } else {
      currentDeviceSize = newDeviceSize;
    }
  });

    if( $(window).outerWidth() > 640 ) {
        var video = $('.video-wrapper.show-for-medium video.mejs__player');
    } else {
        var video = $('.video-wrapper.show-for-small-only video.mejs__player');
    }

    if (video.length > 0) {
        var mediaElementPlayer = getMediaElementPlayer(video);
        var media = mediaElementPlayer.media;

        $(media).one('playing', function () {
            // add class to hide video placeholder image
            $(this).closest('.mejs__player').addClass('mejs__player--started');
        });

        $('.hero-slider__muted').click(function () {
            // toggle video mute when mute icon is tapped
            var isUnMuted = $(this).hasClass('hero-slider__muted--unmuted');

            if (isUnMuted) {
                media.setMuted(true);
                media.setVolume(0);
            } else {
                media.setMuted(false);
                media.setVolume(0.7);
            }

            $(this).toggleClass('hero-slider__muted--unmuted', !isUnMuted);
        });

        if ($(window).outerWidth() >= 800) {
            // autoplay video on desktops
            media.play();
        }
    }

    // $('.hero-slider__mobileplay').click(function () {
    //     // start playing the video when the play button is tapped
    //     media.play();
    //     $('.hero .mejs__container').show();
    //     $(this).hide();
    // });
}

function loadVideos() {
    var videoMobile = $('.mobile-hero-video')
    var videoDesktop = $('.desktop-hero-video')
    if (window.innerWidth > 640) {
        videoDesktop.attr('src', videoDesktop.data('src'))
    } else {
        videoMobile.attr('src', videoMobile.data('src'))
    }

}

function initSliderVideo() {

    if ($('.hero__video').length > 0) {
        // Make sure the slider always fills the whole screen
        coverViewport($('.hero__video iframe'), true);
        $(window).on('resize', function () {
            coverViewport($('.hero__video iframe'), true);
        });
    }
}



function coverViewport(element, scaleToParent) {
	if (typeof scaleToParent === 'undefined') { scaleToParent = false; }

	var viewportWidth, viewportHeight;
	if (scaleToParent) {
		viewportWidth = $(element).parent().innerWidth();
		viewportHeight = $(element).parent().innerHeight();
	} else {
		viewportWidth = $(window).innerWidth();
		viewportHeight = $(window).innerHeight();
	}

	var $element = $(element);

	var elementWidth = $element.attr('width');
	var elementHeight = $element.attr('height');

	// Aspect ratio = height / width
	var viewportRatio = viewportHeight / viewportWidth;
	var videoRatio = elementHeight / elementWidth;

	//console.log('Viewport: ', viewportRatio, ' Video: ', videoRatio);


	var newWidth = (elementWidth / elementHeight) * viewportHeight
	var newHeight = (elementHeight / elementWidth) * viewportWidth;
	var newTop = 0;

	if (viewportRatio > videoRatio) {
		// set video height to the viewport, and extend the video beyond the viewport width, i.e. portrait viewport
		//console.log('crop left and right');
		$element.css('height', viewportHeight);
		$element.css('width', newWidth);
		$element.css('left', '-' + (newWidth - viewportWidth) / 2 + 'px');
		$element.css('top', '0');
	} else if (viewportRatio < videoRatio) {
		// set video width to the viewport, and extend the video beyond the viewport height, i.e. landscape viewport
		//console.log('crop top and bottom');
		$element.css('width', viewportWidth);
		$element.css('height', newHeight);
		$element.css('left', '0');
		$element.css('top', '-' + (newHeight - viewportHeight) / 2 + 'px');
	} else {
		// set the video width AND height to the viewport size, i.e. viewport is the same ratio as the video
		//console.log('no crop');
		$element.css('height', viewportHeight);
		$element.css('width', viewportWidth);
		$element.css('left', '0');
		$element.css('top', '0');
	}
}

function initTimeline() {
    // Custom up/down arrows which update to show the next/previous years within the text of the button
    $('.timeline__items').on('afterChange', function (event, slick, currentSlide) {
        var prevYear = slick.$slides.filter('.slick-current').prev().data('year') || '';
        var nextYear = slick.$slides.filter('.slick-current').next().data('year') || '';

        $('.timeline__items-prev-year').text(prevYear);
        $('.timeline__items-next-year').text(nextYear);
    });

    $('.timeline__items').slick({
        vertical: true,
        slidesToShow: 1,
        verticalSwiping: false,
        infinite: false,
        draggable: false,
        asNavFor: $('.timeline__nav'),
        prevArrow: '<button type="button" class="slick-arrow slick-prev"><span class="timeline__items-prev-year"></span></button>',
        nextArrow: '<button type="button" class="slick-arrow slick-next"><span class="timeline__items-next-year"></span></button>',
        responsive: [
            {
                breakpoint: 641,
                settings: {
                    vertical: false,
                    adaptiveHeight: true
                }
            }
        ]
    });

    // Grab the value of the second year and put that into the next button
    $('.timeline__items-next-year').text($('.timeline__item:eq(1)').data('year'));

    $('.timeline__nav').slick({
        vertical: true,
        slidesToShow: 5,
        verticalSwiping: false,
        infinite: false,
        asNavFor: $('.timeline__items'),
        responsive: [
            {
                breakpoint: 639,
                settings: {
                    vertical: false,
                    slidesToShow: 3,
                    centerMode: true,
                    arrows: false,
                    centerPadding: 0,
                },
            },
        ],
    });

    // Move to the chosen year when clicking on one of the nav buttons
    $('.timeline__year button').on('click', function (e) {
        e.preventDefault();
        $('.timeline__items').slick('slickGoTo', $(this).parent().data('slick-index'));
    });
}


function initOfferFilters() {

    var $filterCheckboxes = $('.offers__filters input[type="checkbox"]');

    $filterCheckboxes.on('change', function() {
        var selectedFilters = {};

        $filterCheckboxes.filter(':checked').each(function() {
            if ( !selectedFilters.hasOwnProperty(this.name) ) {
                selectedFilters[this.name] = [];
            }

            selectedFilters[this.name].push(this.value);
        });

        var $filteredResults = $('.offers__grid-item');

        $.each(selectedFilters, function(name, filterValues) {

            $filteredResults = $filteredResults.filter(function() {
                var matched = false,
                currentFilterValues = $(this).data('offers-filter').split(' ');

                $.each(currentFilterValues, function(_, currentFilterValue) {

                    if( $.inArray(currentFilterValue, filterValues) != -1 ) {
                        matched = true;
                        return false;
                    }
                });

                return matched;
            });
        });

        $('.offers__grid-item').hide().filter($filteredResults).show();
    });

}


function initBlogFilters() {

    var $filterCheckboxes = $('.blog-categories__filters input[type="checkbox"]');

    $filterCheckboxes.on('change', function() {
        var selectedFilters = {};

        $filterCheckboxes.filter(':checked').each(function() {
            if ( !selectedFilters.hasOwnProperty(this.name) ) {
                selectedFilters[this.name] = [];
            }

            selectedFilters[this.name].push(this.value);
        });

        var $filteredResults = $('.blog-categories__grid-item');

        $.each(selectedFilters, function(name, filterValues) {

            $filteredResults = $filteredResults.filter(function() {
                var matched = false,
                currentFilterValues = $(this).data('blog-filter').split(' ');

                $.each(currentFilterValues, function(_, currentFilterValue) {

                    if( $.inArray(currentFilterValue, filterValues) != -1 ) {
                        matched = true;
                        return false;
                    }
                });

                return matched;
            });
        });

        $('.blog-categories__grid-item').hide().filter($filteredResults).show();
    });

}

function initRestaurantBooking() {
    // scroll to table booking widget if #book-table is included in page URL
    var bookTableWidget = $('#book-table');
    var hasBookTableWidget = (bookTableWidget.length > 0);
    var hasBookTableHash = (window.location.hash === '#book-table');

    if (hasBookTableHash && hasBookTableWidget) {
        var $window = $(window);
        $window.scrollTop(bookTableWidget.offset().top - ($window.height() - bookTableWidget.outerHeight(true)) / 2);

        // if there is a button, trigger a click
        var bookButton = bookTableWidget.find('a.book-button');

        if (bookButton.length > 0) {
            $(window).on('load', function () {
                bookButton[0].dispatchEvent(new MouseEvent('click', { cancelable: true, bubbles: true, view: window }));
            });
        }
    }
}
function showMenuButtons() {
    setTimeout(function() {

        if( $('.header__primary-nav-burger').length > 0 ) {
            $('.header__primary-nav-burger').addClass('fade-in');
        }

        if( $('.hotel-header__primary-nav-burger').length > 0 ) {
            $('.hotel-header__primary-nav-burger').addClass('fade-in');
        }
    },2500)
}

function initBookingOverlay() {

	$('a[href*="#/booking/step-1"]').on('click', function () {
        $('body').append('<div class="booking-overlay"></div>');

        if( $('body.theme-le-bristol-paris').length > 0) {
            $("ng-http-loader[spinner='sk-chasing-dots']").css("display", "none");
        }

		document.addEventListener('azdsBookingReady', function(e) {

			$('.booking-overlay').fadeOut(function () {
				$('.booking-overlay').remove();
			});
            $('html').removeClass('booking-overlay-open')
		});
	});


}

function initBlog() {
  $('.blog-hotels__carousel-inner').slick({
    slidesToShow: 4,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });


  // $('.blog-suggestions__carousel-inner').each(function() {
  //   var maxSlides = 3;

  //   if ( $(this).find('.blog-suggestions__item').length < 3 ) {
  //     maxSlides = $(this).find('.blog-suggestions__item').length;
  //   }

  //   $(this).slick({
  //     slidesToShow: maxSlides,
  //     arrows: true,
  //     responsive: [
  //       {
  //         breakpoint: 1024,
  //         settings: {
  //           slidesToShow: maxSlides
  //         }
  //       },
  //       {
  //         breakpoint: 640,
  //         settings: {
  //           slidesToShow: 1
  //         }
  //       }
  //     ]
  //   });
  // });

  // alignSuggestionArrows();
  // $(window).on('resize', alignSuggestionArrows);
  // Foundation.onImagesLoaded($('.blog-suggestions__item img'), alignSuggestionArrows);

  $('.blog-hero__nav-mobile').on('click', function() {
    $('body').addClass('no-scroll');
    $('.header').addClass('opened');
    $('.header').addClass('fixed');
  });

  $('.blog-off-canvas__close').on('click', function() {
    $('body').removeClass('no-scroll');
    $('.header').removeClass('opened');
    $('.header').removeClass('fixed');
  });

  $('.blog-hero__nav-search label').on('click', function() {
    $('.blog-hero__nav-search input').each(function() {
        $(this).addClass('open');
    });
        $('.blog-hero__nav-search label').addClass('open');
        $('.blog-hero__nav-search-close').addClass('open');
  });

  $('.blog-hero__nav-search-close').on('click', function() {
    $('.blog-hero__nav-search input').each(function() {
        $(this).removeClass('open');
    });
        $('.blog-hero__nav-search label').removeClass('open');
        $('.blog-hero__nav-search-close').removeClass('open');
  })

  $('.blog-featured-article__discover .scroll').on('click', function () {
    var nextElement = $(this).closest('.blog-featured-article').next();

    $('html, body').animate({
        scrollTop: nextElement.offset().top - 150,
    });
  });



  $('.blog-comments__reply-button').on('click', function() {
    var commentReplyId = $(this).data('comment-id');
    $('.blog-comments__reply-form .form input[name="ParentCommentId"]').val(commentReplyId);
  })

}

function alignSuggestionArrows() {
  var halfImgHeight = $('.blog-suggestions__item img').first().outerHeight() / 2;
  $('.blog-suggestions__carousel .slick-arrow').css('top', halfImgHeight + 'px');
}



function initHotelOffers() {
    $('.tabs-title a').on('click', function() {
        setTimeout(function () {
            $(window).trigger('resize');
        }, 200);
    })
}

function initCurator() {

    var lang = document.documentElement.lang

    function appendDiv(btnCont, btn, text) {
        var newDiv = $("<div class='translator-wrapper'><span>" + text + "</span></div>");
        btnCont.append(newDiv)
        btn.css('opacity', 0)
        btnCont.css('position', 'relative')
        newDiv.css('position', 'absolute')
        newDiv.css('top', 0)
        newDiv.css('left', 0)
        newDiv.css('width', '100%')
        newDiv.css('height', '100%')
        newDiv.css('background-color', '#fff')
        newDiv.css('display', 'flex')
        newDiv.css('align-items', 'center')
        newDiv.css('justify-content', 'center')
        newDiv.css('pointer-events', 'none')
        var newSpan = $('.translator-wrapper > span')
        newSpan.css('font-size', '0.9rem')
        newSpan.css('line-height', 'rem-calc(15)')
        newSpan.css('color', '#000')
        newSpan.css('letter-spacing', '0.1em')
        newSpan.css('font-family', 'Gill Sans MT Pro')
        newSpan.css('padding-bottom', '6px')
        newSpan.css('border-bottom', '1px solid #000')
        newSpan.css('text-transform', 'lowercase')

    }

    function translateLang() {
        var btnCont = $('.crt-load-more-container')
        var btn = $('.crt-load-more')

        switch(lang) {
            case "de":
                appendDiv(btnCont, btn, "mehr laden")
                break;
            case "fr":
                appendDiv(btnCont, btn, "charger plus")
                break;
            case "es":
                appendDiv(btnCont, btn, "carga más")
                break;
            case "it":
                appendDiv(btnCont, btn,  "carica di più")
                break;
            case "pt":
                appendDiv(btnCont, btn, "carregue mais")
                break;
        }
    }

    setTimeout(translateLang, 2000)
    $( window ).resize(function() {
        translateLang()
    })
}

function initReadMore() {
    var contents = $('.feature-text__text')
    var accWrapper = $('.feature-text__accordion .accordionContent')
    var accBtn = $('.feature-text__accordion .accordionButton')

    function cutContent (content) {
        var cutContent = content.html().split("[CUT]")
        var firstPart = cutContent[0]
        var firstPartWithDots = cutContent[0].slice(0, -3) + "..."
        var secondPart = cutContent[1]
        return {
            firstPart: firstPart,
            firstPartWithDots: firstPartWithDots,
            secondPart:secondPart
        }
    }

    function setHiddenPart(content, i, appendProps) {
        content.css('position', 'relative')
        content.addClass("layerOn")
        content.children().remove()
        content.append(appendProps.firstPartWithDots)
        accWrapper.eq(i).append(appendProps.secondPart)
    }

    function readMore(content, firstPart) {
        content.css('margin-bottom', 0)
        content.children().remove()
        content.append(firstPart)
        content.removeClass("layerOn")
    }

    function readLess(content, firstPartWithDots) {
        content.css('margin-bottom', '20px')
        content.children().remove()
        content.append(firstPartWithDots)
        content.addClass("layerOn")
    }

    function handleStateChange(state, content, appendProps) {
        if (!state) {
            readMore(content, appendProps.firstPart)
            return
        }
        readLess(content, appendProps.firstPartWithDots)

    }

    function handleIsActive (contents) {
        contents.each(function (index){
            if (!$(this).data('is-preview')) {
                accWrapper.eq(index).hide()
                accBtn.eq(index).hide()
                return
            }
            var content = $(this)
            var isReadMore = false
            var contentObj = cutContent(content)
            setHiddenPart(content, index, contentObj)
            accBtn.eq(index).on('click', function () {
                handleStateChange(isReadMore, content, contentObj)
                isReadMore = !isReadMore
            })
        })
    }

    handleIsActive(contents)
}

function initCountdowns() {
    // Replace with your target date
targetDate = new Date(targetDate);

    function updateCountdown() {
        // Check if the countdown element exists
        const countdownElement = document.querySelector('.hero__countdown');
        if (!countdownElement) {
            // If the element doesn't exist, clear the interval to stop further updates
            clearInterval(countdownInterval);
            return;
        }

        const now = new Date();
        const timeDifference = targetDate - now;

        if (timeDifference <= 0) {
            // Countdown has ended
            countdownElement.remove(); // Remove the countdown element immediately
            clearInterval(countdownInterval); // Stop the interval
            return;
        }

        // Calculate the remaining time
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const calcMinutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const minutes = calcMinutes + 1; // This fixes the minutes calculation
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        // Update the display only if more than 0 seconds are left
        if (minutes === 0 && hours === 0 && days === 0 && seconds <= 59) {
            countdownElement.remove();
            clearInterval(countdownInterval);
            return;
        }

        // Update the countdown display
        document.querySelector('.counter__days .number').textContent = days;
        document.querySelector('.counter__hours .number').textContent = hours;
        document.querySelector('.counter__minutes .number').textContent = minutes;
    }

    // Start the countdown
    const countdownInterval = setInterval(updateCountdown, 1000); // Update every second

    updateCountdown(); // Initial call to set the correct values immediately
}
